import constants from "@/constants/index.js";

const formatSuccessData = (
  title = null,
  description = null,
  closeButton = null,
  imageUrl = null
) => {
  return {
    id: constants.PolarPopupType.Success,
    title: title ?? constants.Resources.NewPolarModal.SuccessPopupTitle,
    description: description ?? constants.Resources.NewPolarModal.SuccessPopupDescription,
    closeButton: closeButton ?? constants.Resources.NewPolarModal.ClosePopupButton,
    imageUrl
  };
};

const handleSuccessResponse = (context, ...args) => context.$emit("open-polar-modal", formatSuccessData(...args));

const formatErrorData = (
  response,
  title = null,
  description = null,
  closeButton = null,
  href = null,
) => {
  if (response?.data?.errors) {
    const exception = response.data.errors[Object.keys(response?.data?.errors)[0]][0] + "Exception";

    response.data = { Exception: exception };
  }

  let exceptionDescription = description ?? constants.Resources.NewPolarModal.ErrorPopupDescription;

  if (response?.data?.Exception && constants.Resources.NewExceptions[response.data.Exception]) {
    exceptionDescription = constants.Resources.NewExceptions[response.data.Exception];

    if (response.data?.Arguments) {
      response.data.Arguments.forEach((arg, index) => {
        exceptionDescription = exceptionDescription.replaceAll(`{${index}}`, arg);
      });
    }
  }

  return {
    id: constants.PolarPopupType.Error,
    title: title ?? constants.Resources.NewPolarModal.ErrorPopupTitle,
    description: exceptionDescription,
    closeButton: closeButton ?? constants.Resources.NewPolarModal.ClosePopupButton,
    href
  };
};

const handleErrorResponse = (
  context,
  error,
  ...args
) => {
  if (process.env.NODE_ENV === "development" || window.location.hostname.includes("staging")) {
    console.error(error) // eslint-disable-line
  }

  if (error.response?.status === 403) {
    window.location.href = "";

    return;
  }

  if (error?.response?.status !== 401) {
    context.$emit("open-polar-modal", formatErrorData(error.response, ...args));
  }
};

export default {
  formatSuccessData,
  handleSuccessResponse,
  formatErrorData,
  handleErrorResponse,
};
