<template>
  <div class="v-cookie-agreement--container">
    <div class="v-cookie-agreement--background"></div>
    <div class="v-cookie-agreement--banner">
      <div class="v-cookie-agreement--banner--image-wrapper">
        <img class="v-cookie-agreement--banner--image" :src="data.image" />
      </div>
      <div>
        <h3 class="v-cookie-agreement--title">{{ data.title }}</h3>
        <p class="v-cookie-agreement--description">{{ data.description }}</p>
      </div>
      <div class="v-cookie-agreement--banner--buttons">
        <t2-button blue @click="$emit('button-click')">{{ data.button }}</t2-button>
      </div>
    </div>
  </div>
</template>
<script>
import T2Button from "@atoms/Button/Button.vue";

export default {
  components: {
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["button-click"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-cookie-agreement {
  &--container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 15px;
  }

  &--background {
    background: linear-gradient(180deg, rgba(0, 17, 35, 0) 0%, #000307 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.4;
  }

  &--title {
    color: black;
    text-transform: uppercase;
    font-size: 16px;
    margin: 0 0 14px;
    @media @mobile {
      margin-left: 60px;
      padding-right: 20px;
      margin-bottom: 24px;
      max-width: 210px;
    }
  }

  &--description {
    font-size: 13px;
    line-height: 20px;
    color: #595959;
    margin: 13px 0;
    p a,
    a {
      color: #0a4e9b;
      text-decoration: underline;
    }
    & > p {
      margin: 0;
    }
  }

  &--banner {
    background-color: white;
    border-radius: 8px;
    max-width: 1200px;
    margin: auto 0 32px;
    padding: 32px 80px 32px 47px;
    display: flex;
    align-items: center;
    box-shadow: 0px 15px 31px rgba(140, 151, 174, 0.2);
    position: relative;
    @media @tablet {
      padding: 25px 50px 25px 30px;
    }
    @media @mobile {
      flex-direction: column;
      padding: 30px 20px 14px;
    }

    &--image-wrapper {
      min-width: 76px;
      height: 93px;
      margin-right: 47px;
      @media @tablet {
        margin-right: 30px;
      }
      @media @mobile {
        height: 47px;
        max-width: 39px;
        position: absolute;
        top: 30px;
        left: 20px;
      }
    }
    &--image {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
