<template>
  <transition name="popup--transition">
    <div
      v-show="isOpen"
      class="popup--container"
    >
      <div class="popup--dialog-holder">
        <transition name="popup--dialog-transition">
          <div
            v-if="isOpen"
            class="popup--dialog"
            :class="[
              {
                'popup--dialog---big': isBigPopup,
                'popup--dialog---small': isSmallPopup,
              },
              customClass,
            ]"
          >
            <div class="popup--close">
              <t2-svg
                icon-id="close"
                @click="emitClose"
              />
            </div>
            <div class="popup--content">
              <slot></slot>
            </div>
          </div>
        </transition>
      </div>
      <div
        class="popup--shadow"
        @click="emitClose"
      />
    </div>
  </transition>
</template>
<script>
import T2Svg from "@atoms/Svg/Svg.vue";
import constants from "@/constants/index.js";
export default {
  name: "T2Modal",
  components: {
    T2Svg,
  },
  inject: ["emitter"],
  props: {
    id: {
      type: String,
      default: ""
    },
    customClass: {
      type: [Array, Object, String],
      default: ""
    },
    isBigPopup: {
      default: false,
      type: Boolean,
    },
    isSmallPopup: {
      default: false,
      type: Boolean,
    },
    preventShadowClose: {
      default: false,
      type: Boolean
    }
  },
  emits: ["close"],
  data() {
    return {
      isOpen: false,
      scrolledY: 0,
    };
  },
  mounted() {
    this.emitter.on(constants.ModalControl.Open, this.openModal);
    this.emitter.on(constants.ModalControl.Close, this.closeModal);
  },
  methods: {
    addEvent(name, callback) {
      if (this.emitter.all.has(name)) {
        this.emitter.off(name);
      }

      this.emitter.on(name, callback);
    },
    openModal(payload) {
      if (payload.id !== this.id) {
        return;
      }

      this.isOpen = true;
      this.toggleScreenScroll();
    },
    closeModal(payload) {
      if (payload.id !== this.id) {
        return;
      }

      this.isOpen = false;
      this.toggleScreenScroll();
    },
    emitClose() {
      if (!this.isOpen) {
        return;
      }

      this.emitter.emit(constants.ModalControl.Close, { id: this.id });
      this.$emit("close");
    },
    toggleScreenScroll() {
      let scroll;

      if (window.scrollY !== undefined) {
        scroll = { x: window.scrollX, y: window.scrollY };
      } else {
        const element = document.documentElement;
        const body = document.body;

        scroll = {
          x: element.scrollLeft || body.scrollLeft || 0,
          y: element.scrollTop || body.scrollTop || 0
        };
      }

      if (this.isOpen) {
        this.scrolledY = scroll.y;
      }

      const elements = [
        document.querySelector("body"),
        document.querySelector("html"),
      ];

      for (let i = 0; i < elements.length; i++) {
        elements[i].style.margin = this.isOpen ? 0 : "";
        elements[i].style.position = this.isOpen ? "fixed" : "";
        elements[i].style.top = this.isOpen ? "-" + scroll.y + "px" : "";
      }

      if (this.isOpen) {
        return;
      }

      window.scrollTo(0, this.scrolledY);
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@import (reference) "~@/assets/less/heading.less";
@import (reference) "~@/assets/less/paragraph.less";

.popup--transition-enter-active,
.popup--transition-leave-active {
  transition: opacity 0.45s ease;
  will-change: opacity;
} 

.popup--transition-enter-from,
.popup--transition-leave-to {
  opacity: 0;
}

.popup--transition-enter-active .popup--dialog,
.popup--transition-leave-active .popup--dialog {
  transition: transform 0.45s ease;
} 

.popup--transition-enter-from .popup--dialog,
.popup--transition-leave-to .popup--dialog {
  transform: scale(0.75);
}

.popup {
  &--shadow {
    background-color: fade(@color-black, 60);
    position: fixed;
    left: 0;
    top: 0;
    overflow-x: visible;
    padding: 0 33px 0;
    height: inherit;
    width: 100%;
    max-height: 100vh;
  }
  &--container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: @zindex-modal-container;
    overflow-x: visible;
    overflow-y: scroll;
    padding: 0 33px 0;
    height: 100%;
    width: 100%;
    max-height: 100vh;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    @media screen and (min-width: @media-breakpoint-sm-max) and (max-height: 530px) {
      align-items: flex-start;
    }

    @media @mobile {
      padding: 0;
    }
  }
  &--dialog-holder {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width: 95%;
    min-height: 100%;
    margin: 0 auto;
    padding: 3% 0;
  }
  &--dialog {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-flow: column nowrap;
    margin: auto;
    padding: 33px;
    position: relative;
    background-color: @color-white;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 fade(@color-black, 40);
    transition: all 0.45s ease;
    z-index: @zindex-modal-dialog;

    @media screen and (max-width: @media-mobile-portrait) {
      width: 100%;
      min-height: 400px;
      flex: 1 0 0;
      justify-content: center;
      padding: 33px 15px;
    }
  }
  &--close {
    cursor: pointer;
    display: flex;
    flex-flow: row-reverse nowrap;

    @media screen and (max-width: @media-mobile-portrait) {
      position: absolute;
      top: 33px;
      right: 15px;
    }
  }
  &--close svg {
    fill: @color-blue;
    height: 18px;
    width: 18px;
  }
  &--content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 16px;
    line-height: 22px;
    padding: 28px 0;

    @media screen and (max-width: @media-mobile-portrait) {
      padding: 0;
    }
  }
  &--heading {
    .heading--2;
    margin-top: 0;
  }
  &--heading---black {
    color: @color-black;
  }
  &--sub-heading {
    .heading--3;
  }
  &--caption {
    .paragraph--content;
    text-align: justify;
    width: 100%;
  }
  &--caption---center {
    text-align: center;
  }
  &--image-container {
    padding-top: 20px;
    max-width: 350px;
  }
  &--image {
    width: 100%;
  }
  &--main-icon {
    height: 100%;
    width: auto;
  }
  &--main-icon:nth-child(n + 2) {
    margin-left: 20px;

    @media screen and (max-width: @media-mobile-portrait) {
      margin-left: 10px;
    }
  }
  &--main-icon:not(:only-child) {
    @media screen and (max-width: @media-mobile-portrait) {
      height: 35px;
    }
    @media screen and (max-width: @media-mobile-small-portrait) {
      height: 30px;
    }
  }
  &--main-icon---wrapper {
    height: 5rem;

    @media screen and (max-width: @media-mobile-portrait) {
      height: auto;
    }
    @media screen and (max-width: @media-mobile-small-portrait) {
      height: auto;
    }
  }
  &--total {
    display: flex;
    align-items: flex-end;
    margin-right: 2rem;

    @media screen and (max-width: @media-mobile-portrait) {
      margin-right: 0;
      margin-bottom: 1rem;
      justify-content: center;
    }

  }
  &--total---price {
    .BP-bold;
    font-size: 44px;
    color: @color-blue;
    line-height: 46px;
    margin-bottom: 3px;

    @media @mobile {
      font-size: 44px;
      line-height: 40px;
      vertical-align: bottom;
    }
  }
  &--total---price:not(:last-child) {
    margin-right: 1rem;

    @media @mobile {
      margin-right: 0.5rem;
    }
  }
  &--total---suffix {
    .BP-bold;
    font-size: 22px;
    color: @color-blue;
  }
  &--actions {
    display: flex;
    padding: 30px @grid-padding 0px;
    justify-content: center;

    @media screen and (max-width: @media-mobile-portrait) {
      padding: 40px (@grid-padding / 2) 0;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  &--actions---cta {
    @media screen and (max-width: @media-mobile-portrait) {
      display: flex;
      justify-content: center;
    }
  }
  &--actions---right {
    margin-left: auto;
  }
  &--actions---multiple .v-button:last-child {
    margin-left: 20px;

    @media screen and (max-width: @media-mobile-portrait) {
      margin-left: 10px;
    }
  }
  &--actions-wrapper {
    display: flex;
    flex-flow: column nowrap;
  }
  &--actions-wrapper > .v-button:not([data-dialog-close]) {
    margin-bottom: 10px;
  }
  &--actions-wrapper > .v-button:not([data-dialog-close]):last-of-type {
    margin-bottom: 0;
  }
  &--actions-wrapper > .v-button[data-dialog-close] {
    margin-top: 20px;
  }
  &--multiple-cta {
    display: flex;
    margin: 0 auto;

    @media screen and (max-width: @media-mobile-small-portrait) {
      flex-direction: column;
    }
  }
  &--multiple-cta > .v-button {
    margin-right: 10px;
    
    @media screen and (max-width: @media-mobile-small-portrait) {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
  &--multiple-cta > .v-button:last-child {
    margin-right: 0;
  }
  &--cta {
    padding-top: 40px;
  }
  //modifiers
  &--dialog---big {
    max-width: 900px;
    padding-left: 30px;
    padding-right: 30px;

    @media screen and (max-width: @media-mobile-portrait) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &--dialog---small {
    max-width: 400px;
  }
  &--dialog---plans {
    max-width: 700px;
  }
  &--logo-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
</style>
