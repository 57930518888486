<template>
  <div class="v-summer-campaign-banner">
    <div class="v-summer-campaign-banner--title">{{ data.title }}</div>
    <div class="v-summer-campaign-banner--image-wrapper">
      <img :src="data.image" class="v-summer-campaign-banner--image" />
    </div>
    <div class="v-summer-campaign-banner--description">
      {{ data.description }}   
    </div>
    <div>
      <t2-button class="v-summer-campaign-banner--button" @click="$emit('cta-click')">
        {{ data.button }}
      </t2-button>
    </div>
    <span @click="$emit('close-click')">
      <t2-svg :icon-id="data.iconId" class="v-summer-campaign-banner--close" />
    </span>
  </div>
</template>
<script>
import T2Button from "@atoms/Button/Button.vue";
import T2Svg from "@atoms/Svg/Svg.vue";
export default {
  components: {
    T2Button,
    T2Svg,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['close-click', 'cta-click'],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-summer-campaign-banner {
  position: absolute;
  top: 0;
  background: radial-gradient(30.51% 488.85% at 33.17% 57.31%, #BFEEF9 0%, #4FD1EE 100%);
  height: 130px;
  width: 100%;
  z-index: 50;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 50px 0 47px;
  justify-content: space-between;
  @media @tablet {
    padding-left: 30px;
  }
  @media @mobile {
    flex-direction: column;
    align-items: initial;
    height: auto;
    padding: 24px 18px;
  }
  &--title {
    max-width: 281px;
    font-size: 32px;
    line-height: 46px;
    color: @color-white;
    font-weight: bold;
    text-transform: uppercase;
    @media @tablet {
      font-size: 29px;
    }
    @media @mobile {
      max-width: 100%;
      font-size: 20px;
      line-height: 24px;
    }
  }
  &--image {
    &-wrapper {
      width: 164px;
      max-height: 179px;
      margin-bottom: -5px;
      @media @mobile {
        position: absolute;
        bottom: 0px;
        right: 0;
        z-index: -1;
        width: 207px;
        max-height: 188px;
      }
    }
  }
  &--description {
    color: @color-white;
    font-weight: bold;
    line-height: 20px;
    max-width: 396px;
    font-size: 14px;
    padding: 0 10px;
    @media @tablet {
      max-width: 300px;
    }
    @media @mobile {
      max-width: 209px;
      font-size: 12px;
      line-height: 18px; 
      padding: 5px 0 30px;
    }
  }
  &--button {
    background: linear-gradient(90deg, #ed1a40 0%, #ec0972 100%);
    &:hover {
      background: linear-gradient(90deg, #971b45 0%, #971b45 100%);
    }
  }
  &--close {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 21px;
    width: 21px;
    fill: @color-white;
    cursor: pointer;
  }
}
</style>
