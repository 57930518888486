import store from "@/store/index.js";
import constants from "@/constants/index.js";

const _formatRefillSection = () => [
  {
    iconId: constants.Icons.Wallet,
    name: constants.Resources.NewDashboard.AddCredits,
    url: constants.Routes.Refill,
    spa: true,
    red: true,
  },
  {
    iconId: constants.Icons.Plans,
    name: constants.Resources.NewDashboard.ChangePlan,
    url: constants.Routes.Plans,
    spa: true,
    red: true,
  },
];

const _formatServicesSection = (myRequestsBadge) => [
  {
    iconId: constants.Icons.Security,
    name: constants.Resources.NewDashboard.Whalebone,
    url: constants.Routes.Whalebone,
    spa: true,
    disabled: constants.Configurations.WhaleboneManagementEnabled !== "True",
  },
  {
    iconId: constants.Icons.Paper,
    name: constants.Resources.NewDashboard.UseFactSheet,
    url: constants.Routes.Report,
    spa: true,
  },
  {
    iconId: constants.Icons.Block,
    name: constants.Resources.NewDashboard.PricePlansCancellation,
    url: constants.Routes.PricePlansCancellation,
    spa: true,
  },
  {
    iconId: constants.Icons.SimCard,
    name: constants.Resources.NewDashboard.ESimChange,
    url: constants.Routes.ESimChangeInformation,
    spa: true,
    disabled: store.getters["features/isDisabled"](constants.FeatureManagement.Features.ChangeESim),
  },
  {
    iconId: constants.Icons.PortOutNumber,
    name: constants.Resources.NewDashboard.PortOut,
    url: constants.Routes.PortOut,
    spa: true,
  },
  {
    iconId: constants.Icons.Assignment,
    name: constants.Resources.NewDashboard.BillingAndPayments,
    url: constants.Routes.Invoice,
    spa: true,
  },
  {
    iconId: constants.Icons.Requests,
    name: constants.Resources.NewDashboard.MyRequests,
    url: constants.Routes.MyRequests,
    spa: true,
    badge: myRequestsBadge,
  },
];

const _formatSettingsSection = () =>   [
  {
    iconId: constants.Icons.NavigationMobile,
    name: constants.Resources.NewDashboard.NumberRegistrationPage,
    url: constants.Routes.RegistrationDashboard,
    spa: true,
    red: true,
    disabled: store.getters["features/isDisabled"](constants.FeatureManagement.Features.CustomerRegistration),
  },
  {
    iconId: constants.Icons.PukCode,
    name: constants.Resources.NewDashboard.ShowPukCode,
    event: "pukCode",
    loader: false,
  },
  {
    iconId: constants.Icons.IdCard,
    name: constants.Resources.NewDashboard.ChangeIdCard,
    event: "idCard",
    loader: false,
  },
  {
    iconId: constants.Icons.Settings,
    name: constants.Resources.NewDashboard.UserSettings,
    url: constants.Routes.Settings,
    spa: true,
  },
];

const _formatLogoutSection = () =>   [
  {
    iconId: constants.Icons.Logout,
    name: constants.Resources.NewDashboard.LogOut,
    event: "logOut",
    red: true
  },
];

const formatSubmenu = (myRequestsBadge) => [
  _formatRefillSection(),
  _formatServicesSection(myRequestsBadge),
  _formatSettingsSection(),
  _formatLogoutSection(),
].map(section => section.filter(page => !page.disabled))
.filter(section => section.length > 0);

export default {
  servicesListIndex: 1,
  cardListIndex: 2,
  formatSubmenu,
};
