import dayjs from "dayjs";
import dayjsCustomParseFormat from "dayjs/plugin/customParseFormat";
import dayjsIsBetween from "dayjs/plugin/isBetween";
import constants from "@/constants/index.js";
import bannerConstants from "@/components/information-popups/constants.js";

dayjs.extend(dayjsCustomParseFormat);
dayjs.extend(dayjsIsBetween);

const expirationTimeFormat = "MM/YY";
const dashboardPriority = 3;
const dashboardLocationId = 2;

const _formatInfoBanner = (expiration) => {
  return {
    id: bannerConstants.Ids.CardInformationBanner,
    priority: dashboardPriority,
    locationId: dashboardLocationId,
    text: constants.Resources.NewDashboard.CardInformationBannerInfoMessage
      .replace("{0}", expiration),
    typeId: bannerConstants.Types.Info,
    isUserSpecificBanner: true,
  }
};

const _formatWarningBannerExpired = (expiration) => {
  return {
    id: bannerConstants.Ids.CardInformationBanner,
    priority: dashboardPriority,
    locationId: dashboardLocationId,
    text: constants.Resources.NewDashboard.CardInformationBannerWarningMessage
      .replace("{0}", expiration),
    typeId: bannerConstants.Types.Warning,
    isUserSpecificBanner: true,
  };
}

const _formatWarningBannerDeactivated = () => {
  return {
    id: bannerConstants.Ids.CardInformationBanner,
    priority: dashboardPriority,
    locationId: dashboardLocationId,
    text: constants.Resources.NewDashboard.CardDeactivatedBannerWarningMessage,
    typeId: bannerConstants.Types.Warning,
    isUserSpecificBanner: true,
  };
}

const formatInformationBanner = (expirationDate, status) => {
  if (!expirationDate) {
    return null;
  }

  const formattedExpirationTime = dayjs(expirationDate, expirationTimeFormat).endOf("month").format("YYYY-MM-DD");

  if (isCardExpired(expirationDate)) {
    return _formatWarningBannerExpired(formattedExpirationTime);
  }

  if (status === constants.CreditCardStatus.Deactivated){
    return _formatWarningBannerDeactivated();
  }

  if (isCardExpiringSoon(expirationDate)) {
    return _formatInfoBanner(formattedExpirationTime);
  }

  return null;
};

const isCardExpired = (expirationDate) => {
  if (!expirationDate) {
    return false;
  }

  const expirationTime = dayjs(expirationDate, expirationTimeFormat).endOf("month").add(1, "day");

  return expirationTime.isBefore(dayjs());
};

const isCardExpiringSoon = (expirationDate) => {
  if (!expirationDate) {
    return false;
  }

  const monthBeforeExpirationTime = dayjs(expirationDate, expirationTimeFormat);
  const expirationTime = dayjs(expirationDate, expirationTimeFormat).endOf("month").add(1, "day");
  
  return dayjs().isBetween(monthBeforeExpirationTime, expirationTime);
};

export default {
  formatInformationBanner,
  isCardExpired,
  isCardExpiringSoon,
};
