<template>
  <div class="v-page-header">
    <div
      class="v-page-header--wrapper"
      :class="{ 'v-page-header--wrapper---use-mobile': data.image.mobileUrl }"
    >
      <div class="v-page-header--heading-wrapper">
        <h1 v-if="data.heading" class="v-page-header--heading">
          {{ data.heading }}
        </h1>
        <p v-if="data.description" class="v-page-header--description">
          {{ data.description }}
        </p>
      </div>
      <picture
        v-if="data.image && data.image.url"
        class="v-page-header--image"
        :class="imageAlignmentClass"
      >
        <source
          v-if="data.image.mobileUrl"
          media="(max-width: 768px)"
          :srcset="data.image.mobileUrl"
        />
        <img :src="data.image.url" />
      </picture>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alignOptions: ["top", "middle", "bottom"],
    };
  },
  computed: {
    imageAlignmentClass() {
      const { align } = this.data.image;
      const alignment = align && this.alignOptions.includes(align) ? align : "middle";

      return `v-page-header--image-${alignment}`;
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-page-header {
  margin-bottom: 55px;
  background: linear-gradient(to right, @color-blue, @color-lighter-blue);

  @media screen and (max-width: @grid-max-width) {
    padding: 0 @grid-padding;
  }
  @media @mobile {
    margin-bottom: 17.6px;
    padding: 0 @grid-mobile-padding;
  }

  &---transparent {
    background: unset;
  }
  &--wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    height: 240px;
    margin: 0 auto;
    max-width: @grid-max-width;
    position: relative;

    @media @mobile {
      height: 100px;
    }
  }

  &--heading {
    .BP-bold;
    color: @color-white;
    font-size: 48px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 0 6px 0;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    z-index: 1;
    max-width: 800px;

    @media @tablet {
      max-width: 100%;
      text-align: center;
    }
    @media @mobile {
      font-size: 24px;
      margin: 0 0 2px 0;
    }
  }
  &--heading-wrapper {
    width: 100%;
  }
  &--image {
    height: 100%;
    min-width: 1px;
    position: absolute;
    top: 0;
    width: auto;
    right: 10%;

    & > img {
      height: 100%;
      max-width: inherit;
      max-height: inherit;
    }

    @media screen and (max-width: @grid-max-width) {
      right: 0;
    }
    @media @tablet {
      display: none;
    }

    &-top {
      padding: 0px 0px 50px;
    }
    &-middle {
      padding: 25px 0px;
    }
    &-bottom {
      padding: 50px 0px 0px;
    }
  }
  &--image---primary {
    @media @tablet {
      display: block;
      position: relative;
      top: unset;
      right: unset;
      order: 0;
    }
  }
  &--description {
    color: @color-white;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.45;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    z-index: 1;
    width: 100%;
    max-width: 800px;

    @media @tablet {
      text-align: center;
      max-width: unset;
    }
    @media @mobile {
      font-size: 18px;
    }
  }
  &---red {
    background: linear-gradient(
      to right,
      @color-darkred,
      @color-red,
      @color-lightpink
    );
  }
  &---lightblue {
    background: linear-gradient(
      to right,
      @color-lighter-blue,
      @color-lightblue
    );
  }

  &--wrapper---use-mobile {
    flex-direction: column-reverse;
    margin: 0 auto 30px;

    @media @tablet {
      height: unset;
      margin: 0 auto 45px;
      padding: 25px 0 0;
    }
  }

  &--wrapper---use-mobile &--heading {
    margin: 0;
  }

  &--wrapper---use-mobile &--image {
    @media @tablet {
      display: block;
      right: unset;
      top: unset;
      position: relative;
      max-width: 180px;
      padding: 0;
      margin: 0 0 25px;
    }
    @media @mobile {
      max-width: 260px;
      max-height: 170px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
