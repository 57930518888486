import store from "@/store";
import constants from "@/constants";

const checkCurrentRoute = (context, currentLanguage) => {
  const language = window.location.pathname.split("/")[1];

  if (
    context.$router.currentRoute.value.meta.isDynamic ||
    !context.$router.currentRoute.value.meta.allowAdditionalLanguages
  ) {
    context.$router.replace({ path: store.getters["user/getLanguageRoutes"].Home });
    return true;
  }
  if (
    (constants.Languages.includes(language) && language !== currentLanguage) ||
    (!constants.Languages.includes(language) &&
      currentLanguage !== constants.DefaultLanguage)
  ) {
    if (context.$router.currentRoute.value.meta.alias) {
      const path =
        context.$router.currentRoute.value.meta.alias.find(
          (x) => x.split("/")[1] === currentLanguage
        ) ?? context.$router.currentRoute.value.meta.alias[0];

      sessionStorage.setItem("route", path);
      context.$router.replace({
        path: constants.Routes.LangProxy,
        query: {
          ...context.$route.query,
          ...context.$route.params
        },
      });

      return true;
    }
  }
  return false;
};


const _formatLithuanianRoutes = () => {
  const routes = {};

  Object.keys(constants.Routes).forEach((key) => {
    if (typeof constants.Routes[key] === "string") {
      routes[key] = constants.Routes[key];

      return;
    }

    if (!constants.Routes[key].Path) {
      return ;
    }

    routes[key] = constants.Routes[key].Path;
  });

  return routes;
};

const _formatLanguageRoutes = (routeObject) => {
  const routes = {};

  Object.keys(routeObject).forEach(key => {
    if (typeof routeObject[key] === "string") {
      routes[key] = routeObject[key];

      return;
    }

    if (!routeObject[key].Path) {
      return ;
    }

    routes[key] = routeObject[key].Path;
  });

  return routes;
};

const getLanguageRoutes = () => ({
  LT: _formatLithuanianRoutes(),
  EN: _formatLanguageRoutes(constants.Routes.EN),
  RU: _formatLanguageRoutes(constants.Routes.RU),
})

export default {
  checkCurrentRoute,
  getLanguageRoutes,
};
