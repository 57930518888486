import constants from "@/constants/index.js";
import menu from "@/services/menu.js";
import navigationApi from "@/components/navigation/api/index.js";

const menuStore = {
  namespaced: true,
  state: {
    myRequestsBadge: 0,
    pukCodeLoader: false,
    pukCodeName: "",
    idCardName: "",
    idCardLoader: false,
    navigation: [],
    pukCode: null,
    idCard: null
  },
  mutations: {
    setNavigation(state, value) {
      state.navigation = value;
    },
    setPukCode(state, value) {
      state.pukCode = value;
    },
    setIdCard(state, value) {
      state.idCard = value;
    },
    setMyRequestBadge(state, value) {
      state.myRequestsBadge = value;
      state.navigation[menu.servicesListIndex].forEach((item) => {
        if (item.name !== constants.Resources.NewDashboard.MyRequests) {
          return;
        }
        item.badge = value;
      });
    },
    setPukCodeLoader(state, value) {
      state.pukCodeLoader = value;
      state.navigation[menu.cardListIndex].forEach((item) => {
        if (item.event !== 'pukCode') {
          return;
        }
        item.loader = value;
      })
    },
    setPukCodeName(state, value) {
      state.pukCodeName = value;
      state.navigation[menu.cardListIndex].forEach((item) => {
        if (item.event !== 'pukCode') {
          return;
        }
        item.name = value;
      })
    },
    setIdCardLoader(state, value) {
      state.idCardLoader = value;
      state.navigation[menu.cardListIndex].forEach((item) => {
        if (item.event !== 'idCard') {
          return;
        }
        item.loader = value;
      })
    },
    setIdCardName(state, value) {
      state.idCardName = value;
      state.navigation[menu.cardListIndex].forEach((item) => {
        if (item.event !== 'idCard') {
          return;
        }
        item.name = value;
      })
    },
    resetMenu(state) {
      state.myRequestsBadge = 0;
      state.pukCodeLoader = false;
      state.pukCodeName = constants.Resources.NewDashboard.ShowPukCode,
      state.idCardLoader = false;
      state.idCardName = constants.Resources.NewDashboard.ChangeIdCard,
      state.navigation = [];
    }
  },
  actions: {
    updateNavigation({ state, commit }) {
      commit('setNavigation', menu.formatSubmenu(state.myRequestsBadge));
    },
    loadPukCode({ state, commit }) {
      if (state.pukCode) {
        return Promise.resolve();
      }

      commit('setPukCodeLoader', true);

      return navigationApi.getPukCode()
        .then(({ data }) => {
          commit('setPukCode', data.result);
        })
        .catch(() => {})
        .finally(() => {
          commit('setPukCodeLoader', false);
        });
    },
    showPukCode({dispatch, state, commit}, item) {
      return dispatch('loadPukCode').then(() => {
        commit('setPukCodeName',
          item.name === constants.Resources.NewDashboard.ShowPukCode
            ? state.pukCode
            : constants.Resources.NewDashboard.ShowPukCode
        );
      })
    },
    loadIdCard({ state, commit }) {
      if (state.idCard !== null) {
        return Promise.resolve();
      }

      commit('setIdCardLoader', true);

      return navigationApi.getIdCard()
        .then(({ status, data }) => {
          commit('setIdCard', status === 204 ? "" : data.cardId);
        })
        .catch(() =>{})
        .finally(() => {
          commit('setIdCardLoader', false);
        });
    },
    showIdCard({dispatch, state, commit}, item) {
      return dispatch('loadIdCard').then(() => {
        let name = constants.Resources.NewDashboard.ChangeIdCard;
  
        if (item.name === constants.Resources.NewDashboard.ChangeIdCard) {
          name = state.idCard !== ""
            ? state.idCard
            : constants.Resources.NewDashboard.IdCardIsNotAdded;
        }
  
        commit('setIdCardName', name);
      });
    }
  },
  getters: {
    getSubmenu(state) {
      return state.navigation;
    },
    getPukCode(state) {
      return state.pukCode;
    },
    getIdCard(state){
      return state.pukCode;
    }
  }
}

export default menuStore;