import constants from "@/constants";

const isCampaignActive = () => {
  var currentDate = new Date();
  var campaignValidFrom = new Date(
    constants.Configurations.AwardsCampaignActiveFrom
  );
  var campaignValidTo = new Date(
    constants.Configurations.AwardsCampaignActiveTo
  );
  return campaignValidFrom < currentDate && campaignValidTo > currentDate;
};

const formatPrizesResponse = (response) => {
  response.forEach((prize) => {
    prize.image = `/img/zzzAwardsCampaign/prizes/${prize.id}.png?v=1`;
    prize.suffix = constants.Resources.AwardsCampaign.PriceSuffix;
    prize.name = `${prize.name} ${prize.colors.join("/")}`;
  });

  response.sort((x) => x.priority);

  return response;
};

const formatGifts = (response) => {
  return response.sort((x, y) => x.priority - y.priority)
    .map(g => ({
      id: g.id,
      title: g.name,
      image: g.imageUrls[0],
      imageUrls: g.imageUrls,
      description: g.description,
      price: `${g.price} €`,
      isActive: g.isActive,
    }));
};

export default {
  isCampaignActive,
  formatPrizesResponse,
  formatGifts,
};
