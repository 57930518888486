export default {
  Features: {
    TopUpCard: 1,
    TopUpBank: 2,
    TopUpVoucher: 3,
    TopUpP2P: 4,
    PpPurchaseCreditCard: 5,
    CreditCardRegisterInSettings: 6,
    ChangeESim: 12,
    GoogleLogin: 14,
    FacebookLogin: 15,
    GooglePay: 16,
    ApplePay: 17,
    CustomerRegistration: 20,
  },
  RefreshBlockedFeaturesSeconds: 15
};
