<template>
  <div
    class="dropdown"
    :class="{ 'dropdown---hoverable': !isTouchEnabled }"
    @mouseleave="isDropdownOpen = false"
  >
    <div
      class="dropdown--selected-option"
      :class="{ 'dropdown--selected-option---active': isTouchEnabled && isDropdownOpen }"
      @click="handleSelectToggle"
    >
      <img
        class="dropdown--selected-option-icon"
        :src="selectedOption.flag"
      >
      <span class="dropdown--selected-option-text">
        {{ selectedOption.value }}
      </span>
      <t2-svg
        class="dropdown--selected-option-arrow"
        iconId="rounded-arrow-right"
      />
    </div>
    <div
      class="dropdown--selectable-options"
      :class="{ 'dropdown--selectable-options---active': isTouchEnabled && isDropdownOpen }"
    >
      <div
        v-for="(option, index) in otherOptions"
        :key="index"
        class="dropdown--selectable-option"
        @click="handleSelect(option)"
      >
        <img
          v-lazy="option.flag"
          class="dropdown--selectable-option-icon"
        >
        <span class="dropdown--selectable-option-text">
          {{ option.value }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import T2Svg from "@atoms/Svg/Svg.vue";
export default {
  components: { T2Svg },
  props: {
    options: {
      type: Array,
      required: true,
    }
  },
  emits: ["option-click"],
  data () {
    return {
      isDropdownOpen: false,
      isTouchEnabled: "ontouchstart" in window
        || navigator.maxTouchPoints > 0
        || navigator.msMaxTouchPoints > 0,
    }
  },
  computed: {
    selectedOption() {
      return this.options.find((x) => x.selected);
    },
    otherOptions() {
      return this.options.filter((x) => !x.selected);
    },
  },
  methods: {
    handleSelectToggle() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    handleSelect(payload) {
      this.isDropdownOpen = false;
      this.$emit("option-click", payload);
    },
  }
}
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";
.dropdown {
  position: relative;

  &---hoverable:hover {
    .dropdown--selected-option {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      & > .dropdown--selected-option-arrow {
        transform: rotate(-90deg);
      }
    }
    .dropdown--selectable-options {
      max-height: 300px;
    }
  }

  &--selected-option {
    background-color: @color-light;
    border-radius: 6px;
    padding: 6px 12px;
    cursor: pointer;

    display: flex;
    align-items: center;

    &---active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      & > .dropdown--selected-option-arrow {
        transform: rotate(-90deg);
      }
    }
  }
  &--selectable-options {
    position: absolute;
    background-color: @color-light;
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s ease-in-out;
    width: 100%;
    z-index: 1;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    top: 90%;

    &---active {
      max-height: 300px;
    }
  }
  &--selectable-option {
    padding: 6px 12px;
    cursor: pointer;

    display: flex;
    align-items: center;
    
    &:first-child {
      padding-top: 18px;
    }

    &:hover > .dropdown--selectable-option-text {
      color: @color-darkred;
    }
  }
  &--selected-option-icon,
  &--selectable-option-icon {
    height: 24px;
    width: 24px;
    border-radius: 100%;
  }
  &--selected-option-text,
  &--selectable-option-text {
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 24px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }
  &--selected-option-arrow {
    transform: rotate(90deg);
    fill: @color-blue;
    height: 12px;
    width: 12px;

    transition: transform .5s ease-in-out;
  }
}
</style>