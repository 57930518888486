import LanguageService from "@/services/language.js";
import TokenService from "@/services/token.js";
import UserApi from "@/api/user.js";
import SettingsApi from "@/views/settings-personal-info/api/index.js";
import NavigationApi from "@/components/navigation/api/index.js";
import constants from "@/constants/index.js";

const user = {
  state: {
    language: constants.DefaultLanguage,
    isLoggedIn: false,
    currentMsisdn: null,
    forceFinishRegistration: false,
    forcePasswordChange: false,
    remindPasswordChange: false,
    tryAutoLogin: null,
    balance: null,
    profile: null,
    allRoutes: LanguageService.getLanguageRoutes(),
    actionReminders: null,
    userProfilePromise: null,
    userInfoPromise: null,
  },
  namespaced: true,
  mutations: {
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem(constants.LocalStorage.Language, language);
      document.documentElement.setAttribute('lang', language);
    },
    setIsLoggedIn(state, status) {
      state.isLoggedIn = status;
    },
    setCurrentMsisdn(state, msisdn) {
      state.currentMsisdn = msisdn;
    },
    setForceFinishRegistration(state, value) {
      state.forceFinishRegistration = value;
      localStorage.setItem(constants.LocalStorage.ForceFinishRegistration, value);
    },
    setForcePasswordChange(state, value) {
      state.forcePasswordChange = value;
      localStorage.setItem(constants.LocalStorage.ForceFinishPasswordRemind, value);
    },
    setRemindPasswordChange(state, value) {
      state.remindPasswordChange = value;
    },
    setTryAutoLogin(state, value) {
      state.tryAutoLogin = value;
    },
    setBalance(state, value) {
      state.balance = value;
    },
    setProfile(state, value) {
      state.profile = value;
    },
    setReminders(state, value) {
      state.actionReminders = value;
    },
    setUserProfilePromise(state, value) {
      state.userProfilePromise = value;
    },
    setUserInfoPromise(state, value) {
      state.userInfoPromise = value;
    },
  },
  actions: {
    setActionReminders({ commit, state }, forceUpdate = false) {
      if (forceUpdate) {
        commit("setReminders", null);
      }

      if (state.actionReminders !== null) {
        return Promise.resolve();
      }

      return UserApi.getActionReminders().then(({ data }) => {
        commit("setReminders", data);
        commit("setForceFinishRegistration", data.forceFinishRegistration);
        commit("setForcePasswordChange", data.forceFinishPasswordRemind);
        commit("setRemindPasswordChange", data.remindPasswordChange);
      })
    },
    setAuthToken({ commit, dispatch }, token) {
      constants.ActiveCampaigns = undefined;
      localStorage.setItem(constants.LocalStorage.SelfcareJwtToken, token);

      commit("setIsLoggedIn", true);
      commit("setCurrentMsisdn", TokenService.decodeToken(token).Msisdn);

      return dispatch("setActionReminders", true);
    },
    setUserDataFromToken({ commit }) {
      const token = localStorage.getItem(
        constants.LocalStorage.SelfcareJwtToken
      );

      if (token !== null) {
        const isTokenExpired = TokenService.isTokenExpired(token);

        if (isTokenExpired) {
          localStorage.removeItem(constants.LocalStorage.SelfcareJwtToken);
        } else {
          commit("setIsLoggedIn", true);
          commit("setCurrentMsisdn", TokenService.decodeToken(token).Msisdn);
        }
      }
    },
    setForceUnfinishedUserMoves({ commit }) {
      const forceFinishRegistration = localStorage.getItem(
        constants.LocalStorage.ForceFinishRegistration
      ) === "true";
      commit("setForceFinishRegistration", forceFinishRegistration);

      const forceFinishPasswordRemind = localStorage.getItem(
        constants.LocalStorage.ForceFinishPasswordRemind
      ) === "true";
      commit("setForcePasswordChange", forceFinishPasswordRemind);
    },
    clearUserData({ commit }) {
      localStorage.removeItem(constants.LocalStorage.SelfcareJwtToken);
      commit("setIsLoggedIn", false);
      commit("setCurrentMsisdn", null);
      commit("setForceFinishRegistration", false);
      commit("setForcePasswordChange", false);
      commit("setRemindPasswordChange", false);
      commit("setReminders", null);
      commit("setProfile", null);
      commit("setUserProfilePromise", null);
      commit("setUserInfoPromise", null);
    },
    loadProfile({ commit, state, getters }, reload = false) {
      if (!reload && (getters.isProfileLoaded || state.userProfilePromise)) {
        return state.userProfilePromise;
      }

      const fetchPromise = SettingsApi.getUserData();
      commit("setUserProfilePromise", fetchPromise);

      return fetchPromise.then(({ data }) => {
        commit("setProfile", data);
      });
    },
    loadUserInfo({ commit, state }, reload = false) {
      if (!reload && state.userInfoPromise !== null) {
        return state.userInfoPromise;
      }

      const fetchPromise = NavigationApi.getUserInfo();
      commit("setUserInfoPromise", fetchPromise);

      return fetchPromise;
    },
  },
  getters: {
    getLanguage: (state) => {
      return state.language;
    },
    isDefaultLanguage: (state) => {
      return state.language === constants.DefaultLanguage;
    },
    getCurrentMsisdn: (state) => {
      return state.currentMsisdn;
    },
    isLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    getToken: () => {
      return localStorage.getItem(constants.LocalStorage.SelfcareJwtToken);
    },
    isForceFinishRegistration: (state) => {
      return state.forceFinishRegistration;
    },
    isForcePasswordChange: (state) => {
      return state.forcePasswordChange;
    },
    isRemindPasswordChange: (state) => {
      return state.remindPasswordChange;
    },
    tryAutoLogin: (state) => {
      return state.tryAutoLogin;
    },
    getBalance: (state) => {
      return state.balance;
    },
    isProfileLoaded: (state) => {
      return state.profile !== null;
    },
    getProfile: (state) => {
      return state.profile;
    },
    getValidatedEmail: (state) => {
      if (state.profile) {
        return state.profile.isEmailValidated ? state.profile.email : null;
      }

      return undefined;
    },
    getLanguageRoutes: (state) => {
      const lang = state.language.toUpperCase();
      return state.allRoutes[lang] || state.allRoutes.LT;
    },
  },
};

export default user;
