<template>
  <header
    v-bind="$attrs"
    class="header"
    :class="{
      'header---scrolled': hidden,
      'header---dark': dark
    }"
  >
    <div class="header--container">
      <div
        class="header--hamburger-wrapper"
        :class="{ 'header--hamburger-wrapper---open': isMainMenuOpen }"
      >
        <t2-button class="header--hamburger-link" @click="$emit('toggle-main-menu')">
          <span class="header--hamburger" />
        </t2-button>
      </div>
      <div class="header--menu-container">
        <div class="header--wrapper">
          <div class="header--logo">
            <a
              class="header--logo-link"
              :href="data.default.index.url"
              @click.prevent="$emit('link-click', data.default.index)"
            >
              <img
                v-if="dark"
                class="header--logo-image"
                src="img/pildyk_logo.png?v=7"
                alt="Logo"
              />
              <img
                v-else
                class="header--logo-image"
                src="img/pildyk_logo.gif?v=7"
                alt="Logo"
              />
            </a>
          </div>
          <nav class="header--left-navigation" aria-label="Navigation left">
            <ul class="header--navigation-list">
              <li
                v-for="(link, index) in data.links"
                :key="'hl-' + index"
                class="header--navigation-item"
              >
                <a
                  class="header--navigation-link"
                  :href="link.href"
                  @click.prevent="$emit('link-click', link)"
                >
                  <span class="header--navigation-text">{{ link.name }}</span>
                </a>
              </li>
              <li class="header--navigation-item header--navigation-item---login header--navigation-item---tablet header--navigation-item---account">
                <t2-button
                  class="header--navigation-link---account header--navigation-link---cta"
                  :class="{ 'header--navigation-link---account-logged-in': userLogged }"
                  @click="$emit('link-click', data.default.submenu)"
                >
                  <t2-svg
                    class="header--account-icon"
                    icon-id="profile-round"
                  />
                  {{ accountText }}
                  <div
                    v-if="userLogged && isSm"
                    class="mobile-menu--cta-arrow-btn"
                  >
                    <div class="mobile-menu--cta-arrow" />
                  </div>
                </t2-button>
              </li>
            </ul>
          </nav>
          <nav class="header--right-navigation" aria-label="Navigation right">
            <ul class="header--navigation-list">
              <li class="header--navigation-item header--navigation-item---language">
                <div
                  class="header--language-select"
                  @mouseleave="menu.languageSelectOpen = false"
                  @mouseover="menu.languageSelectOpen = true"
                >
                  <div class="header--selected-language">
                    <div class="header--selected-language--value">
                      {{ selectedLanguage.value }}
                    </div>
                    <img
                      class="header--selected-language--image"
                      :src="selectedLanguage.flag"
                    />
                    <t2-svg
                      class="header--selected-language-arrow"
                      :class="{ 'header--selected-language-arrow---active': menu.languageSelectOpen }"
                      icon-id="rounded-arrow-right"
                    />
                  </div>
                  <div
                    class="header--language-dropdown"
                    :class="{ 'header--language-dropdown---active': menu.languageSelectOpen }"
                    @mouseleave="menu.languageSelectOpen = false"
                  >
                    <div
                      v-for="(lang, index) in otherLanguages"
                      :key="`lang-${index}`"
                      class="header--selected-language"
                      @click="handleLanguageSelect(lang)"
                    >
                      <div class="header--selected-language--value">
                        {{ lang.value }}
                      </div>
                      <img
                        class="header--selected-language--image"
                        :src="lang.flag"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="header--question-wrapper">
        <t2-button
          class="header--question-link"
          :class="{
            'header--question-link---active': isUserMenuOpen,
            'header--question-link---space': data.default.submenu.loginText
          }"
          @click="$emit('user-button-click')"
        >
          <t2-svg
            class="header--question-icon"
            :class="{ 'header--question-icon---space': data.default.submenu.loginText }"
            icon-id="user-icon-filled"
          />
          <span
            v-show="data.default.submenu.loginText"
            class="header--question-text"
          >
            {{ data.default.submenu.loginText }}
          </span>
        </t2-button>
      </div>
    </div>
  </header>
  <t2-header-dropdown
    v-if="isLg"
    :data="data"
    :user-logged="userLogged"
    :menu-class="{ 'mobile-menu---open': isMainMenuOpen }"
    :sub-menu="isUserMenuOpen"
    @link-click="$emit('link-click', $event)"
    @event-click="$emit('event-click', $event)"
    @language-select="$emit('language-select', $event)"
  />
</template>
<script>
import T2Button from "@atoms/Button/Button.vue";
import T2Svg from "@atoms/Svg/Svg.vue";
import T2HeaderDropdown from "@atomic/organisms/HeaderDropdown/HeaderDropdown.vue";
import { useBreakpoints } from "@/composables/useBreakpoints.js";
export default {
  components: {
    T2Button,
    T2HeaderDropdown,
    T2Svg,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    userLogged: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    isUserMenuOpen: {
      type: Boolean,
      default: false,
    },
    isMainMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "link-click",
    "event-click",
    "language-select",
    "toggle-main-menu",
    "user-button-click"
  ],
  setup() {
    const { isSm, isLg } = useBreakpoints();

    return { isSm, isLg };
  },
  data() {
    return {
      settings: {
        scrollEnabled: false,
        minScroll: 58,
        lastScrollPos: 0,
        currentScrollPos:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop,
        scrollDiff: 0,
        documentHeight: document.body.offsetHeight,
        windowHeight: window.innerHeight,
      },
      menu: {
        languageSelectOpen: false,
      },
    };
  },
  computed: {
    canHide() {
      return this.isSm && !this.isMainMenuOpen && !this.isUserMenuOpen;
    },
    hidden() {
      if (!this.canHide) {
        return false;
      }

      if (this.settings.currentScrollPos <= this.settings.minScroll) {
        return false;
      }

      if (
        this.settings.scrollDiff < 0 &&
        this.settings.currentScrollPos + this.settings.windowHeight <
          this.settings.documentHeight &&
        !this.isMainMenuOpen
      ) {
        return true;
      }

      if (this.settings.scrollDiff > 0) {
        return false;
      }

      return true;
    },
    isScrollDisabledOnBody() {
      return this.isLg && (this.isMainMenuOpen || this.isUserMenuOpen);
    },
    accountText() {
      return this.userLogged
        ? this.data.default.submenu.name
        : this.data.default.submenu.loginText;
    },
    selectedLanguage() {
      return this.data.default.languages.find((x) => x.selected);
    },
    otherLanguages() {
      return this.data.default.languages.filter((x) => !x.selected);
    },
  },
  watch: {
    isScrollDisabledOnBody(newVal) {
      if (newVal) {
        return document.body.classList.add("body---no-scroll");
      }
      document.body.classList.remove("body---no-scroll");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleScroll() {
      this.settings.currentScrollPos =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.settings.scrollDiff = this.settings.lastScrollPos - this.settings.currentScrollPos;
      this.settings.lastScrollPos = this.settings.currentScrollPos;
    },
    handleResize() {
      this.settings.scrollDiff = this.settings.lastScrollPos - this.settings.currentScrollPos;
      this.settings.documentHeight = document.body.offsetHeight;
      this.settings.windowHeight = window.innerHeight;
      this.settings.lastScrollPos = this.settings.currentScrollPos;
    },
    handleLanguageSelect(payload) {
      this.menu.languageSelectOpen = false;
      this.$emit("language-select", payload);
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@header-shadow-color: fade(@color-black, 8%);
//.header--hamburger-link, .header--question-link
@header--hamburger-question-link-background-color: #ecf0ff;
@header--hamburger-question-link-border-color: #dae0f9;
//style
.header {
  background-color: @color-white;
  box-shadow: 0 3px 10px 0 @header-shadow-color;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: @zindex-header;
  transition: transform 200ms;
  width: 100%;
  height: 85px;
  
  @media @laptop {
    width: 100vw;
  }
  @media @mobile {
    height: 60px;
  }

  &---scrolled {
    @media @mobile {
      transform: translate(0, -130%);
    }
  }

  @color-local-dark: #111827;

  &---dark {
    background-color: @color-local-dark;

    .header {
      &--right-navigation {
        background-color: @color-local-dark;
      }
      &--navigation {
        &-link {
          color: @color-white;
        }
      }

      &--selected-language--value {
        color: @color-white;
      }

      &--selected-language-arrow {
        fill: @color-white;
      }

      &--language-dropdown {
        background-color: @color-local-dark;

        .header--selected-language--value {
          color: @color-white;

          &:hover {
            color: @color-blue;
          }
        }
      }

      &--hamburger-link,
      &--hamburger-link:hover {
        background-color: @color-blue;
        border-color: @color-blue;
      }

      &--hamburger {
        background-color: @color-white;
        &:before,
        &:after {
          background-color: @color-white;
        }
      }

      &--question-link {
        background-color: @color-blue;
        border-color: @color-blue;
      }

      &--question-icon {
        fill: @color-white;
      }
    }
  }

  &--container {
    max-width: @grid-max-width;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 100%;
    position: relative;

    @media screen and (max-width: @grid-max-width) {
      padding: 0 @grid-padding;
    }
    @media @mobile {
      padding: 0 @grid-mobile-padding;
    }
  }

  &--menu-container {
    width: 100%;

    @media @laptop {
      width: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
    @media @mobile {
      justify-content: center;
    }
  }

  &--hamburger-wrapper,
  &--question-wrapper {
    padding: 20px 20px 20px 0;
    display: none;
  }

  &--hamburger-link {
    width: 35px;
  }

  &--hamburger-link,
  &--question-link {
    height: 35px;
    min-width: unset;
    background-color: @header--hamburger-question-link-background-color;
    border-radius: 4px;
    border: solid 1px @header--hamburger-question-link-border-color;
    display: block;
    position: relative;

    &:active,
    &:hover {
      background-color: @header--hamburger-question-link-background-color;
      outline: none;
    }
  }

  &--question-link:after,
  &--question-link:before {
    background-color: @color-blue;
    border-radius: 4px;
    height: 3px;
    position: absolute;
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 19px;
  }

  &--question-link {
    display: flex;
    align-items: center;
    padding: 12px 10px;
    min-width: 35px;
    position: relative;

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50%;
      opacity: 0;
      transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
        opacity .2s ease-in;
    }
  }

  &--question-link---active {
    .header--question-icon {
      opacity: 0;
      transition: opacity .1s linear;
    }

    &:before,
    &:after {
      opacity: 1;
    }

    &:before {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
  }

  &--question-link---space {
    &:before,
    &:after {
      left: 16%;
    }
  }

  &--question-wrapper {
    @media @laptop {
      display: flex;
      padding: 0 0 0 20px;
      align-items: center;
    }
  }

  &--question-text {
    color: @color-blue;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0;
  }

  &--question-icon {
    height: 16px;
    width: 11px;
    fill: @color-blue;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .1s linear .15s;
    &---space {
      margin-right: 8px;
      position: unset;
      transform: unset;
      left: unset;
      top: unset
    }
  }

  &--hamburger-wrapper {
    @media @mobile {
      padding: 12px 12px 12px 0;
    }
    @media @laptop {
      display: flex;
      align-items: center;
    }
  }

  &--hamburger-link {
    position: relative;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    transition-timing-function: linear;
    padding: 12px 10px;
  }

  &--hamburger,
  &--hamburger:after,
  &--hamburger:before {
    background-color: @color-blue;
    border-radius: 4px;
    height: 3px;
    position: absolute;
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 19px;
  }

  &--hamburger {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0s linear 0.13s;
    display: block;

    &:before,
    &:after {
      display: block;
      content: "";
    }

    &:before {
      top: 6px;
      transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:after {
      top: 12px;
      transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &--right-wrapper {
    display: flex;
  }

  &--logo {
    flex: 0 0 105px;
    width: 105px;
    max-width: none;
    height: 92px;
    text-align: center;
    top: 8px;
    z-index: 4;
    @media screen and (max-width: @grid-max-width) {
      height: 80px;
      width: 90px;
      flex-basis: 90px;
    }
    @media screen and (min-width: @media-breakpoint-md-min) and (max-width: @media-breakpoint-lg-max) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    @media @mobile {
      height: 59px;
      width: 66px;
      flex-basis: 66px;
    }
  }

  &--logo-link {
    display: block;
  }

  &--logo-image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    top: 4px;

    @media screen and (max-width: @grid-max-width) {
      top: 10px;
    }

    @media @laptop {
      top: 4px;
    }
  }

  &--left-navigation {
    flex: 1 1 100%;
    padding-left: 60px;
    @media screen and (max-width: @grid-max-width) {
      padding-left: 50px;
    }
    @media @laptop {
      text-align: left;
    }
  }

  &--right-navigation {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    padding-left: 20px;
    background-color: @color-white;
  }

  &--left-navigation,
  &--right-navigation {
    background-color: @color-white;
    display: flex;
    align-items: center;
    text-align: right;
    @media @laptop {
      display: none;
    }
  }

  &--navigation-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &--navigation-item {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 17px 0;
    position: relative;
    &:not(:last-child) {
      padding: 0 4px 0 0;
      margin: 0 auto 0 0;
      @media screen and (max-width: @grid-max-width) {
        padding-right: 20px;
      }
    }
    @media @laptop {
      display: none;
    }

    &---login {
      padding: 0;
    }
    &---language {
      display: flex;
      height: 100%;
      padding: 0;
    }
  }

  &--navigation-text {
    .BP-light;
    font-weight: 300;
    font-size: 16px;
    line-height: 17px;
    display: inline-block;

    @media screen and (max-width: 1090px) {
      font-size: 14px;
    }
  }

  &--navigation-icon {
    transition: transform 0.4s ease-in-out;
  }
  &--account-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    vertical-align: bottom;
    fill: @color-white;
    @media screen and (max-width: @grid-max-width) {
      width: 16px;
      height: 16px;
      margin-right: 15px;
    }
  }

  //modifiers
  &--hamburger-wrapper---open {
    .header--hamburger {
      top: 6px;
      transition-delay: 0.22s;
      background-color: transparent;

      &:before {
        top: 0;
        transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
          transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 10px, 0) rotate(45deg);
      }

      &:after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 10px, 0) rotate(-45deg);
      }
    }
  }

  &--navigation-link {
    .BP-light;
    color: @color-black;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
      color: @color-blue;
      transition: background-color 0.2s ease-in-out, color 0.4s ease-in-out;
    }

    &---cta {
      .BP-bold;
      font-weight: 700;
      border-radius: 10px;
      min-width: 150px;
      display: inline-block;
      padding: 12px 20px;
      text-align: center;

      font-size: 16px;
      line-height: 19px;
      @media screen and (max-width: @grid-max-width) {
        min-width: 100px;
        font-size: 14px;
        line-height: 16px;
        padding: 10px 20px;
      }
    }

    &---account {
      display: flex;
      align-items: center;
      background-color: @color-blue;
      padding: 12px 20px;
      border-color: @color-blue;
      color: @color-white;
      @media screen and (max-width: @grid-max-width) {
        padding: 10px;
        padding-left: 15px;
        padding-right: 19px;
      }

      &:hover {
        border-color: @color-darkblue;
        background-color: @color-darkblue;
        color: @color-white;
      }

      &-logged-in {
        border-color: @color-blue;
        background-color: @color-blue;

        &:hover {
          border-color: @color-darkblue;
          background-color: @color-darkblue;
        }
      }
    }
  }

  &--navigation-item---tablet {
    @media @laptop {
      display: inline-block;
    }
  }

  &---static {
    position: relative;
  }

  .header--navigation-link---account {
    background-color: @color-blue;
    padding: 12px 20px;
    border-color: @color-blue;
    color: @color-white;
    @media screen and (max-width: @grid-max-width) {
      padding: 10px;
      padding-left: 15px;
      padding-right: 19px;
    }

    &:hover {
      border-color: @color-darkblue;
      background-color: @color-darkblue;
      color: @color-white;
    }

    &-logged-in {
      border-color: @color-blue;
      background-color: @color-blue;

      &:hover {
        border-color: @color-darkblue;
        background-color: @color-darkblue;
      }
    }
  }
  &--language-select {
    display: flex;
    align-items: center;
  }
  &--selected-language {
    display: flex;
    align-items: center;
    cursor: default;
    padding: 10px 0;

    &--image {
      max-width: 20px;
      height: auto;
    }
    &--value {
      min-width: 22px;
      font-size: 16px;
      margin-right: 4px;
      text-transform: uppercase;
    }
  }
  &--selected-language-arrow {
    height: 10px;
    width: 10px;
    transform: rotate(90deg);
    margin-left: 6px;
    transition: transform .3s ease-in-out;

    &---active {
      transform: rotate(-90deg);
    }
  }
  &--language-dropdown {
    position: absolute;
    background-color: @color-white;
    right: 0;
    transform: translateY(-110%);
    top: 100%;
    z-index: -1;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    -webkit-transition: unset;
    transition: unset;

    &:hover, &---active {
      transform: translateY(0%);
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
      -webkit-transition: transform .5s ease-in-out, box-shadow .5s ease-in-out .5s;
      transition: transform .5s ease-in-out, box-shadow .5s ease-in-out .5s;
    }

    .header--selected-language {
      padding: 10px 16px 10px 10px;
      cursor: pointer;
      transition: color .2s ease-in-out;
      
      &:hover {
        color: @color-blue;
      }
    }
  }
}
</style>
