import { nextTick } from "vue";

const handleHeight = (_el, _binding, vnode) => {
  nextTick(() => {
    let innerHeight = vnode.elm.scrollHeight;
    if (vnode.componentInstance.$parent.isOpen) {
      vnode.componentInstance.height = innerHeight * 2 + "px";
    }

    if (vnode.componentInstance.$parent.data.active) {
      vnode.componentInstance.height = innerHeight * 2 + "px";
    }
  });
};

export default {
  beforeMount: handleHeight,
  updated: handleHeight,
};
