<template>
  <i v-if="iconId != 'progress-ball'" :class="[customClass]">
    <t2-svg :icon-id="iconId" />
  </i>
  <div
    v-else-if="iconId == 'progress-ball'"
    class="progress-ball"
    :class="[customClass]"
  >
    <div class="progress-ball--inner">
      <div class="progress-ball--water">
        <t2-svg
          icon-id="wave"
          custom-class="progress-ball--water-wave progress-ball--water-wave---back"
        />
        <t2-svg
          icon-id="wave"
          custom-class="progress-ball--water-wave progress-ball--water-wave---front"
        />
      </div>
    </div>
  </div>
</template>
<script>
import T2Svg from "@atoms/Svg/Svg.vue";
export default {
  components: {
    T2Svg,
  },
  props: {
    customClass: {
      type: [Array, Object, String],
      default: "",
    },
    iconId: {
      type: String,
      required: true,
    },
  },
};
</script>
