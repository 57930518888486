<template>
  <h2 v-bind="$attrs">
    <slot></slot>
    <t2-svg
      icon-id="small-arrow-down"
      custom-class="heading-arrow--title-svg"
    />
  </h2>
</template>
<script>
import T2Svg from "@atoms/Svg/Svg.vue";
export default {
  components: { T2Svg },
  inheritAttrs: false,
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@import (reference) "~@/assets/less/fonts.less";
.heading-arrow--title-svg {
  display: none;
  fill: @color-dark;
  height: 6px;
  position: absolute;
  right: 0;
  top: 5px;
  transition: transform 0.2s ease-in-out;
  width: 10px;
  @media @mobile {
    display: block;
  }
}
</style>
