import store from "@/store/index.js";
import UserService from "@/services/user.js";
import TagManagerService from "@/services/tagManager.js";
import constants from "@/constants/index.js";

const getAutoLoginPromise = () => {
  return store.getters["user/tryAutoLogin"] === null
    ? UserService.checkAutoLogin()
    : Promise.resolve();
};

const tryPushPageView = (to, from) => {
  if (to.path !== from.path && Object.keys(from.meta).length !== 0) {
    TagManagerService.pushPageView(window.location.origin + to.path + to.hash);
  }
};

const getTokenAndRedirect = async (to) => {
  await UserService.getToken();
  await store.dispatch("user/setActionReminders", true);

  const isLoggedIn = store.getters["user/isLoggedIn"];
  const forcePath = UserService.getForceFinishPath();

  if (isLoggedIn && forcePath) {
    return forcePath;
  }

  if (isLoggedIn && to.query.returnUrl) {
    return to.query.returnUrl;
  }

  return {
    path: store.getters["user/getLanguageRoutes"].Login,
    query: { useAutoLogin: "false" },
  };
};

const getReturnQuery = (to) => ({ returnUrl: to.query.returnUrl || to.path });

const redirectToAutoLogin = (to) => {
  const query = to.query.returnUrl
    ? `?${constants.ReturnParameter}=${to.query.returnUrl}`
    : "";

  location.href = `${constants.Env.BrandtierUrl}/auth/auto-login${query}`;
};

const transformHashFromRouteLanguageToActual = (
  hash,
  routeLanguage,
  actualLanguage
) => {
  if (hash === "#_=_") {
    return "";
  }

  if (routeLanguage === actualLanguage) {
    return hash;
  }

  const hashObject = constants.Hashes[routeLanguage.toUpperCase()];
  const matchedHashKey = Object.keys(hashObject).find(
    (key) => hashObject[key] === hash
  );

  if (matchedHashKey) {
    return constants.Hashes[actualLanguage.toUpperCase()][matchedHashKey];
  }

  return hash;
};

const getRouteAndActualLanguage = (to, from) => {
  let routePrefix = to.fullPath.split("/")[1];
  let routeLanguage = constants.Languages.includes(routePrefix)
    ? routePrefix
    : constants.DefaultLanguage;
  let actualLanguage = constants.DefaultLanguage;

  if (Object.keys(from?.meta || {}).length > 0) {
    actualLanguage = store.getters["user/getLanguage"];

    return { actualLanguage, routeLanguage };
  }

  if (to.meta.overrideLocalStorageLanguage) {
    actualLanguage = routeLanguage;
  } else if (localStorage.getItem("language")) {
    actualLanguage = localStorage.getItem("language") ?? routeLanguage;
  }

  store.commit("user/setLanguage", actualLanguage);

  return { actualLanguage, routeLanguage };
};

const setRouteComponent = (route) => {
  return {
    ...route,
    component: () =>
      import(
        /* webpackChunkName: "[request]" */ `../views/${route.location}/index.vue`
      ),
  };
};

export default {
  getAutoLoginPromise,
  tryPushPageView,
  getTokenAndRedirect,
  getReturnQuery,
  redirectToAutoLogin,
  transformHashFromRouteLanguageToActual,
  getRouteAndActualLanguage,
  setRouteComponent,
};
