<template>
  <div>
    <div class="v-information-banners--container">
      <t2-information-banner
        v-for="(banner, index) in banners"
        :key="`banner-${index}`"
        class="v-information-banners--item"
        :text="banner.text"
        :description="banner.description"
        :info="banner.typeId == bannerTypes.Info"
        :warning="banner.typeId == bannerTypes.Warning"
        :error="banner.typeId == bannerTypes.Error"
        @banner-close="handleBannerClose(index, banner.id)"
      />
    </div>
    <t2-information-modal
      v-if="modalToShow"
      :data="modalToShow"
      @button-click="handleModalButtonClick"
    />
  </div>
</template>
<script>
import T2InformationBanner from "@molecules/InformationBanner";
import BannerApi from "./api";
import constants from "./constants";
import commonConstants from "@/constants";
import InformationPopupsServices from "./services";
import T2InformationModal from "@organisms/Modals/InformationModal";

export default {
  name: "InformationPopups",
  components: {
    T2InformationBanner,
    T2InformationModal,
  },
  inject: ["emitter"],
  data() {
    return {
      allBanners: [],
      banners: [],
      modals: [],
      modal: {
        id: "information-modal",
      },
    };
  },
  computed: {
    modalToShow() {
      let modal;
      
      if (this.modals[0]) {
        modal = this.modals[0];
        modal.title = modal.title || commonConstants.Resources.NewPolarModal.InformationBannerDefaultTitle;
        modal.description = modal.text;
        modal.button =
          modal.buttonText && modal.buttonUrl ? modal.buttonText : null;
        modal.buttonClose = modal.buttonClose || commonConstants.Resources.NewPolarModal.InformationBannerCloseButton;
        modal.image = modal.imageUrl ?? constants.DefaultImage;
      }

      return modal;
    },

    bannerTypes() {
      return constants.Types;
    },
  },
  watch: {
    $route() {
      this.setInitialPopups();
    },

    modals: {
      handler: function() {
        if (this.modalToShow) {
          this.$nextTick(() => {
            this.modal.imageUrl = this.modalToShow.imageUrl;
            this.emitter.emit(commonConstants.ModalControl.Open, this.modal);
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.emitter.on(commonConstants.Modals.AddPopUp, this.handleBannerAdd);
    this.emitter.on(commonConstants.ModalControl.Close, this.handleModalClose);
    this.emitter.on(commonConstants.Events.MainResourcesLoaded, this.loadMainResources);
    this.emitter.on(commonConstants.Events.UpdateInformationBanners, this.updateInformationBanners);
    this.emitter.on(constants.Events.UserBannerClear, this.handleUserSpecificBannerRemoval);
  },
  beforeUnmount() {
    this.emitter.off(commonConstants.Modals.AddPopUp, this.handleBannerAdd);
    this.emitter.off(commonConstants.ModalControl.Close, this.handleModalClose);
    this.emitter.off(commonConstants.Events.MainResourcesLoaded, this.loadMainResources);
    this.emitter.off(commonConstants.Events.UpdateInformationBanners, this.updateInformationBanners);
    this.emitter.off(constants.Events.UserBannerClear, this.handleUserSpecificBannerRemoval);
  },
  methods: {
    handleBannerAdd(bannerData) {
      if (this.allBanners.find(x => x.id === bannerData.id)) {
        return;
      }

      this.allBanners.push(bannerData);
      this.banners.push(bannerData);
    },
    handleUserSpecificBannerRemoval() {
      this.allBanners = this.allBanners.filter(banner => !banner.isUserSpecificBanner);
    },
    loadMainResources() {
      return BannerApi.getInformationBanners()
        .then(({ data }) => {
          this.allBanners = data;

          return this.setInitialPopups();
        })
        .catch(() => {
          // Do nothing
        });
    },
    setInitialPopups() {
      this.banners = this.filterUnclosedPopupsByRoute(
        this.allBanners.filter((x) => x.typeId !== constants.Types.Modal)
      );

      if (this.$store.getters['user/isLoggedIn']
        && this.$store.getters['user/isRemindPasswordChange']
        && !this.allBanners.find(x => x.id === constants.Ids.PasswordChangeReminder)
      ) {
        this.allBanners.push(InformationPopupsServices.formatRemindPasswordChangeModal());
      }

      this.modals = this.filterUnclosedPopupsByRoute(this.allBanners.filter((x) => x.typeId === constants.Types.Modal));
    },

    filterUnclosedPopupsByRoute(banners) {
      const filteredBanners = banners
        .filter((x) => !this.getClosedPopups().find((y) => y === x.id))
        .filter((x) => x.locations.some(locationId => {
          const includesLocation = this.$route.meta.alias?.includes(
            constants.Locations[locationId]
          );

          if (constants.Locations[locationId] === commonConstants.Routes.Home) {
            return includesLocation;
          }

          return this.$route.path.includes(constants.Locations[locationId]) || includesLocation;
        }))
        .sort((a, b) => (a.priority > b.priority ? 1 : -1));

      if (this.$store.getters["user/isLoggedIn"]) {
        return filteredBanners;
      }

      return filteredBanners
        .filter((x) => !x.showOnlyToLoggedInUsers);
    },

    handleBannerClose(index, id) {
      this.saveClosedPopup(id);
      this.banners.splice(index, 1);
    },

    handleModalClose(payload) {
      if (payload.id === constants.Ids.InformationModal) {
        this.saveClosedPopup(this.modalToShow.id);
        this.modals.shift();
      }
    },

    getClosedPopups() {
      const closed = sessionStorage.getItem(
        commonConstants.SessionStorage.ClosedInformationPopups
      );

      return closed ? JSON.parse(closed) : [];
    },

    saveClosedPopup(id) {
      const closed = this.getClosedPopups();
      closed.push(id);

      sessionStorage.setItem(
        commonConstants.SessionStorage.ClosedInformationPopups,
        JSON.stringify(closed)
      );
    },

    handleModalButtonClick() {
      if (this.modalToShow.buttonUrl) {
        if (this.modalToShow.buttonUrl.includes("https://")) {
          window.location.href = this.modalToShow.buttonUrl;
        } else {
          this.$router.push(this.modalToShow.buttonUrl);
        }
      }

      this.emitter.emit(commonConstants.ModalControl.Close, { id: constants.Ids.InformationModal });
    },

    updateInformationBanners() {
      const closedPopups = this.getClosedPopups();
      const temporaryPopupIds = [
        constants.Ids.CardInformationBanner,
        constants.Ids.PasswordChangeReminder
      ];
      const temporaryPopups = this.allBanners
        .filter((x) => (x.showOnlyToLoggedInUsers && x.showOncePerSession) || x.isUserSpecificBanner)
        .map((x) => x.id);

      sessionStorage.setItem(
        commonConstants.SessionStorage.ClosedInformationPopups,
        JSON.stringify(closedPopups.filter((x) => !temporaryPopups.includes(x) || !temporaryPopupIds.includes(x)))
      );
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";

.v-information-banners {
  &--container {
    position: absolute;
    top: 95px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: @zindex-page-content-default + 1;

    @media @mobile {
      top: 70px;
    }
  }

  &--item {
    margin-bottom: 10px;
  }
}
</style>
