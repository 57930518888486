<template>
  <div class="v-fonos-game-banner">
    <div class="v-fonos-game-banner--title">{{ data.title }}</div>
    <img :src="data.imageDesktop" class="v-fonos-game-banner--image-desktop" />
    <img :src="data.imageMobile" class="v-fonos-game-banner--image-mobile" />
    <div class="v-fonos-game-banner--description">{{ data.description }}</div>
    <div>
      <t2-button
        v-loader="data.buttonLoader"
        class="v-fonos-game-banner--button"
        pink
        @click="$emit('win-prize-click')"
      >
        {{ data.button }}
      </t2-button>
    </div>
    <span @click="$emit('close-click')">
      <t2-svg icon-id="close" class="v-fonos-game-banner--close" />
    </span>
  </div>
</template>
<script>
import T2Button from "@atoms/Button/Button.vue";
import T2Svg from "@atoms/Svg/Svg.vue";

export default {
  components: {
    T2Button,
    T2Svg,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "win-prize-click",
    "close-click"
  ]
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-fonos-game-banner {
  position: absolute;
  top: 0;
  background: linear-gradient(96.62deg, #004498 34.81%, #4589da 76.82%);
  height: 130px;
  width: 100%;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5% 0 3%;
  overflow: hidden;
  @media @mobile {
    flex-direction: column;
    align-items: initial;
    height: auto;
    padding: 32px 24px 25px;
    background: linear-gradient(180deg, #004498 25.52%, #4589da 100%);
  }
  &--title {
    font-size: 28px;
    line-height: 38px;
    max-width: 170px;
    font-weight: bold;
    color: @color-white;
    text-transform: uppercase;
    padding: 0 10px 0 0;
    @media @mobile {
      font-size: 24px;
      line-height: 36px;
      max-width: 100%;
      margin-bottom: 10px;
      padding: 0;
    }
  }

  &--image {
    &-desktop {
      max-width: 100%;
      height: 130px;
      @media @tablet {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @media @tablet {
        display: flex;
      }
      @media @mobile {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }
  &--description {
    color: @color-white;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    max-width: 312px;
    padding: 0 10px;
    @media @mobile {
      font-size: 10px;
      line-height: 16px;
      margin-bottom: 15px;
      max-width: 216px;
      padding: 0;
    }
  }
  &--button {
    padding: 12px 25px;
    @media @tablet {
      padding: 12px;
    }
  }
  &--close {
    fill: @color-white;
    position: absolute;
    cursor: pointer;
    height: 21px;
    width: 21px;
    right: 12px;
    top: 12px;
  }
}
</style>
