class Validation {
  constructor(_el, binding) {
    this.errors = {};
    this.object = binding.value;
    this.instance = binding.instance;
    this.dirty = false;
    // this.hasErrors = false

    this.handleInput = this.handleInput.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleInput(rules, event = null) {
    let text;

    if (this.instance.modelValue === null) {
      text = null;
    } else if (this.instance.modelValue?.value) {
      text = this.instance.modelValue.value.trim();
    } else {
      text = this.instance.modelValue.trim();
    }

    this.errors = this.validate(rules, text);

    this.instance.hasErrors = this.hasErrors;
    this.instance.handleError = this.handleInput;
    this.instance.errors = this.errors;
    this.instance.object = this.object;
    if (
      event &&
      this.instance.blur &&
      this.instance.blur.indexOf(event.keyCode) !== -1
    ) {
      return;
    }
    if (this.dirty && this.object.translations) {
      this.handleError(this.object.translations[this.errors[0]], this.instance);
    }
  }

  handleError(message, context = this) {
    if (context.errors.length && message) {
      context.error = message;
    } else {
      context.error = null;
    }
  }

  hasErrors() {
    let text;

    if (this.instance.modelValue === null) {
      text = null;
    } else if (this.instance.modelValue?.value) {
      text = this.instance.modelValue.value.trim();
    } else {
      text = this.instance.modelValue.trim();
    }

    this.errors = this.validate(this.object.rules, text);

    if (this.errors.length && this.object.translations) {
      this.dirty = true;
      this.handleError(this.object.translations[this.errors[0]], this.instance);
      return true;
    }
    return false;
  }

  validate(rules, value) {
    const rulesArray = rules.split("|");
    const errors = [];
    const isFieldRequired = rules.includes("required");

    if (!isFieldRequired && !value) {
      return errors;
    }

    rulesArray.forEach((rule) => {
      switch (rule) {
        case "required":
          if (!value) {
            errors.push("required");
          }
          break;
        default:
          if (!this.regex(rule, value)) {
            errors.push(rule);
          }
          errors.key = this.regex(rule, value);
          break;
      }
    });

    return errors;
  }

  regex(rule, value) {
    let regex = null;
    switch (rule) {
      case "msisdn":
        // eslint-disable-next-line
        regex = new RegExp(/^6[0-9]{7}$/);
        break;
      case "email":
        regex = new RegExp(
          // eslint-disable-next-line
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        break;
      case "pildykId":
        // eslint-disable-next-line
        regex = new RegExp(/^[0-9]{11}$/);
        break;
      case "personalNumber":
        // eslint-disable-next-line
        regex = new RegExp(/[3-6][0-9]{2}[0,1][0-9][0-9]{2}[0-9]{4}$/);
        break;
      case "password":
        // eslint-disable-next-line
        regex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);
        return regex.test(String(value));
      case "price":
        // eslint-disable-next-line
        regex = new RegExp(/^\d{1,4}(?:\.\d{1,2})?$/);
        break;
      case "safetyCode":
        // eslint-disable-next-line
        regex = new RegExp(/^\d{13}$/);
        break;
      case "image":
        // eslint-disable-next-line
        regex = new RegExp(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i);
        break;
      case "isicNumber":
        // eslint-disable-next-line
        regex = new RegExp(/^[a-zA-Z0-9]{14}$/);
        break;
      case "domain":
        // eslint-disable-next-line
        regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/);
        break;
      default:
        // eslint-disable-next-line
        regex = new RegExp(rule);
        break;
    }
    return regex.test(String(value).toLowerCase());
  }
}

const listenerDataAttribute = "data-keyup-listener";

const _setValidation = (el, binding, vnode) => {
  if (!binding.value) {
    return ;
  }

  const validation = new window.valdation(el, binding, vnode);
  validation.handleInput(binding.value.rules);

  if (el[listenerDataAttribute]) {
    el.removeEventListener("keyup", el[listenerDataAttribute]);
  }

  el[listenerDataAttribute] = validation.handleInput.bind(this, binding.value.rules);
  el.addEventListener("keyup", el[listenerDataAttribute]);
};

window.valdation = Validation;

export default {
  beforeMount: _setValidation,
  beforeUpdate(el, binding, vnode) {
    if (JSON.stringify(binding.value) === JSON.stringify(binding.oldValue)) {
      return;
    }

    _setValidation(el, binding, vnode);
  },
  beforeUnmount(el) {
    el.removeEventListener("keyup", el[listenerDataAttribute]);
  },
};
