<template>
  <t2-dashboard-navigation
    :navigation="navigation"
    :navigation-links="$store.getters['menu/getSubmenu']"
    :show-mobile-banner="showMobileBanner"
    @navigation-click="handleNavigationClick"
    @event-click="handleEventClick"
  />
</template>
<script>
import dayjs from "dayjs";
import T2DashboardNavigation from "@organisms/DashboardNavigation/DashboardNavigation.vue";
import PolarModalService from "@/services/polarModal.js";
import NavigationApi from "./api/index.js";
import constants from "@/constants/index.js";

export default {
  components: { T2DashboardNavigation },
  inject: ["emitter"],
  inheritAttrs: false,
  data() {
    return {
      navigation: {
        user: {},
      },
      account: {
        accountExpirationDate: null,
        name: null,
        phone: null,
        isEsim: false,
      }
    };
  },
  computed: {
    showMobileBanner() {
      return this.$route?.meta?.useUserInformation;
    }
  },
  created() {
    return Promise.all([
      this.loadNewRequestCount(),
      this.loadUserInfo(),
    ]);
  },
  methods: {
    handleEventClick(item) {
      const events = {
        'pukCode': (item) => this.$store.dispatch("menu/showPukCode", item),
        'idCard': (item) => this.$store.dispatch("menu/showIdCard", item),
        'logOut': () => this.emitter.emit("navigationClick", { url: constants.Routes.Logout }),
      };

      return events[item.event](item);
    },
    handleNavigationClick(item) {
      return this.emitter.emit("navigationClick", item);
    },
    formatUserNavigation() {
      return {
        name: this.account.name,
        defaultName: constants.Resources.NewDashboard.PildykUser,
        msisdn: constants.MsisdnPrefix + this.account.phone,
        billValidity: constants.Resources.NewDashboard.SimValidUntil +
          this.formatDate(this.account.accountExpirationDate),
        isEsim: this.account.isEsim,
        eSimCardIconDesktop: constants.Icons.ESimCardBlack,
        eSimCardIconMobile: constants.Icons.ESimCardWhite,
      };
    },
    loadUserInfo() {
      this.account.phone = this.$store.getters["user/getCurrentMsisdn"];

      return this.$store.dispatch("user/loadUserInfo")
        .then(({ data }) => {
          this.account.isEsim = data.isEsim;
          this.account.name = data.fullName;
          this.account.accountExpirationDate = data.expirationDate;
          this.navigation.user = this.formatUserNavigation();
        })
        .catch((response) => PolarModalService.handleErrorResponse(this, response));
    },
    loadNewRequestCount() {
      return NavigationApi.getNewRequestCount()
        .then(({ data }) => this.$store.commit("menu/setMyRequestBadge", data.result))
        .catch((response) => PolarModalService.handleErrorResponse(this, response));
    },
    formatDate(value) {
      return value
        ? dayjs(String(value)).format(constants.DateFormat) + " d."
        : "-";
    }
  },
};
</script>
