<template>
  <div class="user-profile">
    <t2-loader-box
      v-if="Object.keys(navigation.user) == 0"
      class="user-profile--loader-box"
    />
    <t2-user-profile
      v-else
      :user="navigation.user"
      :class="{ 'user-profile-info---tablet-hidden': !showMobileBanner }"
    />
    <ul
      v-for="(listItem, index) in navigationLinks"
      :key="'dnl-' + index"
      class="user-profile--nav"
    >
      <li
        v-for="(item, secondIndex) in listItem"
        :key="secondIndex"
        class="user-profile--nav-item"
        :class="{
          [`user-profile--nav-item---${item.red ? 'red' : 'blue'}`]: true,
          'user-profile--nav-item---selected': item.selected
        }
        "
        @click.prevent="$emit(eventName(item.event), item)"
      >
        <div>
          <t2-svg class="user-profile--nav-icon" :icon-id="item.iconId" />
        </div>
        <a
          v-loader="item.loader"
          class="user-profile--nav-link"
          :href="item.url || '#'"
        >
          {{ item.name }}
          <t2-badge v-if="item.badge && item.badge.length > 0" :text="item.badge" />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import T2Svg from "@atoms/Svg/Svg.vue";
import T2Badge from "@atoms/Badge/Badge.vue";
import T2LoaderBox from "@atoms/LoaderBox/LoaderBox.vue";
import T2UserProfile from "@organisms/UserProfile/UserProfile.vue";
export default {
  components: {
    T2Svg,
    T2Badge,
    T2LoaderBox,
    T2UserProfile,
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
    navigationLinks: {
      type: Array,
      required: true,
    },
    showMobileBanner: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "navigation-click",
    "event-click",
  ],
  methods: {
    eventName(event) {
      return event ? "event-click" : "navigation-click";
    }
  }
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";

.user-profile {
  background-color: @color-light;
  padding: 60px 19px 60px 50px;
  transition: padding 0.2s ease-in-out;
  max-width: 316px;
  width: 100%;

  @media @laptop {
    padding-left: 20px;
    max-width: unset;
    width: unset;
    background-color: @color-white;
    padding: 0;
    flex: 0 0;
  }

  &--loader-box {
    height: 152px;

    @media @laptop {
      height: 135px;
    }
  }

  &--nav {
    padding: 20px 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid @color-grey-10;

    @media @laptop {
      display: none;
    }
  }
  &--nav-item {
    padding: 8px 10px 8px 45px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    min-height: 40px;
    display: flex;
    align-items: center;
    position: relative;

    &:not(:first-child):not(:last-child) {
      margin: 6px 0;
    }

    &:hover {
      background-color: @color-grey-5;
    }
  }
  &--nav-link {
    .BP-light;
    font-size: 16px;
    color: @color-dark;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    &:hover {
      color: @color-red;
    }

    &.loading {
      color: transparent;
      pointer-events: none;
    }
  }
  &--nav-icon {
    display: inline-block;
    position: relative;
    max-width: 24px;
    max-height: 24px;
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    left: 13px;
  }
  /// USER-PROFILE--NAV - MODIFIERS
  &--nav-item---red .user-profile--nav-link {
    color: @color-red;
  }
  &--nav-item---red .user-profile--nav-icon {
    fill: @color-red;
  }
  &--nav-item---blue .user-profile--nav-link {
    color: @color-blue;
  }
  &--nav-item---blue .user-profile--nav-icon {
    fill: @color-blue;
  }
  &--nav-item---selected {
    background-color: @color-grey-5;
  }
  &--nav-item---selected .user-profile--nav-link {
    font-weight: bold;
  }
}
</style>
