<template>
  <span v-if="text && text != ''" data-tooltip :class="[customClass]">
    <slot></slot>
    <div
      class="tooltip-cloud"
      :class="{
        'tooltip-cloud---bottom': bottom,
        'tooltip-cloud---right': right,
        'tooltip-cloud---left': left,
        'tooltip-cloud---top-left': topLeft,
      }"
    >
      <div class="tooltip-cloud--triangle"></div>
      <div class="tooltip-cloud--wrapper">{{ text }}</div>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    customClass: {
      type: [String, Array, Object],
      default: ""
    },
    top: {
      type: Boolean,
      default: true,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    topLeft: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@triangle-offset: calc(100% + 10px);
@cloud-offset: calc(100% + 15px);

//tooltip
[data-tooltip]:hover {
  .tooltip-cloud {
    opacity: 1;
    z-index: 2;
  }
}
[data-tooltip] {
  position: relative;
  display: inline-block;
}

.tooltip-cloud {
  opacity: 0;
  z-index: -1;
  top: -10px;
  transition: none;
  pointer-events: none;
  left: 0;
  position: relative;

  &---active {
    opacity: 1;
    z-index: 2;
  }

  &--triangle {
    background-color: @color-white;
    border-bottom: 1px solid #eaeaff;
    border-right: 1px solid #eaeaff;
    bottom: @triangle-offset;
    height: 10px;
    left: 0;
    position: absolute;
    transform: rotate(45deg);
    width: 10px;
    z-index: 2;
  }

  &--wrapper {
    background-color: @color-white;
    border: 1px solid #eaeaff;
    bottom: @cloud-offset;
    left: -15px;
    border-radius: 3px;
    margin: 0;
    position: absolute;
    width: 230px;
    color: @color-dark;
    display: table-cell;
    font-size: 14px;
    line-height: 1.42em;
    text-transform: none;
    vertical-align: middle;
    padding: 10px;
    min-height: auto;
    @media @mobile {
      left: 0px;
      transform: translate(-50%, 0);
    }
  }

  &---bottom {
    .tooltip-cloud {
      &--wrapper {
        bottom: initial;
        top: @cloud-offset;
      }

      &--triangle {
        transform: rotate(225deg);
        bottom: initial;
        top: @triangle-offset;
      }
    }
  }

  &---right {
    .tooltip-cloud {
      &--wrapper {
        bottom: 50%;
        left: @cloud-offset;
        transform: translate(0, 50%);
      }

      &--triangle {
        bottom: 50%;
        left: @triangle-offset;
        transform: translate(0, 50%) rotate(-225deg);
      }
    }
  }

  &---left {
    .tooltip-cloud {
      &--wrapper {
        bottom: 50%;
        right: @cloud-offset;
        left: initial;
        transform: translate(0, 50%);
      }

      &--triangle {
        bottom: 50%;
        right: @triangle-offset;
        left: initial;
        transform: translate(0, 50%) rotate(-45deg);
      }
    }
  }
  &---top-left {
    .tooltip-cloud {
      &--wrapper {
        left: -72px;
      }
    }
  }
}
</style>
