import constants from "@/constants/index.js";

const formatConfirmModal = () => ({
  image: constants.Images.Confirmation.Url,
  title: constants.Resources.NewDashboard.ConfirmDeactivatedCardReplacementModal,
  decline: constants.Resources.NewDashboard.Cancel,
  confirm: constants.Resources.NewDashboard.Proceed,
});

export default {
  formatConfirmModal,
};
