import { nextTick } from "vue";

const manageImageStyles = (el) => {
  nextTick(() => {
    const images = el.getElementsByTagName("img");

    if (!images.length) {
      return;
    }

    Array.from(images).forEach((image) => {
      image.style.height = null;
      image.style.width = null;
    });
  });
};

export default {
  beforeMount: manageImageStyles,
  updated: manageImageStyles,
};
