<template>
  <t2-campaign-banner
    class="v-banner"
    :style="
      `--backgroundDesktop: url(${data.backgroundDesktop});--backgroundMobile: url(${data.backgroundMobile})`
    "
  >
    <div class="v-banner--wrapper">
      <h1>{{ data.title }}</h1>
      <div class="v-banner--images">
        <img :src="data.imageFirst" />
        <img :src="data.imageSecond" />
      </div>
      <p>{{ data.description }}</p>
      <t2-button
        v-loader="data.loader"
        pink
        @click="$emit('button-click')"
      >
        {{ data.button }}
      </t2-button>
    </div>
  </t2-campaign-banner>
</template>
<script>
import T2CampaignBanner from "@atomic/molecules/CampaignBanner/CampaignBanner.vue";
import T2Button from "@atoms/Button/Button.vue";

export default {
  components: {
    T2CampaignBanner,
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["button-click"],
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";

.v-banner {
  display: flex;
  width: 100%;
  background-image: var(--backgroundDesktop);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;

  @media @mobile {
    background-image: var(--backgroundMobile);
  }

  &--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    width: 100%;

    @media @mobile {
      flex-direction: column;
      align-items: start;
      padding: 18px;
      max-width: 400px;
      margin: 0 auto;
      position: relative;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 38px;
    text-transform: uppercase;
    max-width: 328px;
    margin: 0;

    @media @mobile {
      font-size: 18px;
      line-height: 26px;
      max-width: 200px;
      margin-bottom: 20px;
    }
  }

  h1,
  p {
    color: @color-white;
  }

  p {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    margin: 0;

    @media @mobile {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 30px;
      max-width: 210px;
    }
  }

  img {
    max-width: 87px;
    width: 100%;
    height: auto;

    @media @mobile {
      max-width: 71px;
    }
  }

  &--images {
    @media @mobile {
      position: absolute;
      right: 50px;
      bottom: 18px;
      max-width: 71px;
    }
  }
}
</style>
