const updateTableScroll = (el) => {
  const tables = el.getElementsByTagName("table");

  if (!tables.length) {
    return;
  }

  Array.from(tables).forEach((table) => {
    const parent = table.parentNode;

    if (parent.classList.contains("table-draggable")) {
      return;
    }

    const wrapper = document.createElement("div");
    wrapper.classList.add("table-draggable");
    parent.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  });
};

export default {
  beforeMount: updateTableScroll,
  updated: updateTableScroll,
};
