export default {
  Components: {
    Main: "t2-info-main",
    ActionHistory: "t2-info-action-history",
  },
  LandingComponents: {
    Main: "t2-awards-campaign-landing-main",
    Prizes: "t2-awards-campaign-landing-prizes",
  },
  LandingHashes: {
    Main: "pagrindinis",
    Prizes: "prizai",
  },
  MedalTypes: {
    MobileAppDownload: "MobileAppDownload",
    PricePlanOrder: "PricePlanOrder",
    Refill: "Refill",
    SocialNetworkOrder: "SocialNetworkOrder",
    DirectMarketing: "DirectMarketing",
    ConsentTrafficLocation: "ConsentTrafficLocation",
    ConsentThirdParty: "ConsentThirdParty",
    EmailConfirmation: "EmailConfirmation",
    DataUsagePerMonth: "DataUsagePerMonth",
    SmsSentPerMonth: "SmsSentPerMonth",
    CreditCardRegistration: "CreditCardRegistration",
    CallsInMinutesPerMonth: "CallsInMinutesPerMonth",
    P2pRefill: "P2pRefill",
    P2pPlanOrder: "P2pPlanOrder",
  },
  Modals: {
    MedalReceive: "awards-campaign-medal-receive-modal",
    PrizeDelivery: "awards-campaign-prize-delivery-modal",
    PrizeDetails: "awards-campaign-prize-details-modal",
    Consents: "awards-campaign-consents-modal",
  },
  PrizeTypes: {
    Nothing: 1,
    Physical: 2,
    Plan: 3,
    Lottery: 4,
    VirtualData: 5,
    VirtualVoice: 6,
    VasOrder: 7,
    ForSale: 8 
  },
  PrizeLocation: "/img/zzzSummerCampaign2023/winnings/",
  WheelStatus: {
    Ready: "ready",
    Started: "started",
    Confirm: "confirm",
    Finished: "finished",
  },
};
