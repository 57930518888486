import constants from "@/constants";

const isCampaignActive = (startDate, endDate) => {
  const currentDate = new Date();
  const campaignValidFrom = new Date(startDate);
  const campaignValidTo = new Date(endDate);

  return campaignValidFrom < currentDate && campaignValidTo > currentDate;
};

const isWheelCampaignActive = () => {
  const wheelCampaign = constants.ActiveCampaigns.find(
    (x) => x.name === constants.AvailableCampaigns.Wheel
  );

  return wheelCampaign !== undefined;
};

const showCampaign = (name) => {
  const campaign = constants.ActiveCampaigns?.find(
    (x) => x.name === name
  ) || false;

  return campaign && isCampaignActive(campaign.activeFrom, campaign.activeTo);
}

const getItemPurchaseCampaignStatus = () => {
  const campaign = _getCampaignByName(
    constants.AvailableCampaigns.ItemPurchase
  );

  if (campaign === undefined) return constants.CampaignStatus.Inactive;

  return campaign.status;
};

const formatCampaignBanner = (isPromo) => {
  return {
    title: isPromo
      ? constants.Resources.AirPodsCampaign.BannerPromoTitle
      : constants.Resources.AirPodsCampaign.BannerTitle,
    description: isPromo
      ? constants.Resources.AirPodsCampaign.BannerPromoDescription
      : constants.Resources.AirPodsCampaign.BannerDescription,
    image: constants.Images.EarpodsCampaign.Campaign.Banner,
    button: constants.Resources.AirPodsCampaign.BannerButton,
  };
};

const formatInitialPageData = () => {
  return {
    popup: null,
    loader: true,
    progress: [
      {
        label: constants.Resources.AirPodsCampaign.CampaignStep1,
        status: 0,
      },
      {
        label: constants.Resources.AirPodsCampaign.CampaignStep2,
        status: -1,
      },
      {
        label: constants.Resources.AirPodsCampaign.CampaignStep3,
        status: -1,
      },
    ],
  };
};

const formatRefillSuccessPopup = () => {
  return {
    image: constants.Images.Success.Url,
    title: constants.Resources.AirPodsCampaign.RefillSuccessPopup,
    iconId: constants.Icons.CloseBold,
    show: true,
  };
};

const formatEmailConfirmationSuccessPopup = () => {
  return {
    image: constants.Images.Success.Url,
    title: constants.Resources.AirPodsCampaign.EmailConfirmationSuccessPopup,
    iconId: constants.Icons.CloseBold,
    show: true,
  };
};

const formatFirstTab = (prizes, isPromo, fullPrizePrice) => {
  const fsg = prizes.find(
    (x) => x.type === constants.ItemPurchaseCampaignPrizesTypes.Fsg
  );
  const sk = prizes.find(
    (x) => x.type === constants.ItemPurchaseCampaignPrizesTypes.Sk
  );

  return {
    discounted: isPromo,
    header: {
      title: constants.Resources.AirPodsCampaign.Step1HeaderTitle,
      subtitle: isPromo
        ? constants.Resources.AirPodsCampaign.Step1HeaderSubtitle
        : null,
      description: isPromo
        ? constants.Resources.AirPodsCampaign.Step1HeaderPromoDescription
        : constants.Resources.AirPodsCampaign.Step1HeaderDescription,
      image: isPromo
        ? "/img/zzzEarpodsCampaign/2022/firstStepHeaderDiscounted.png"
        : "/img/zzzEarpodsCampaign/2022/firstStepHeader.png?v=2",
      backgroundImages: {
        desktop: "/img/zzzEarpodsCampaign/2022/firstStepBgDesktop.png",
        tablet: "/img/zzzEarpodsCampaign/2022/firstStepBgTablet.png",
        mobile: "/img/zzzEarpodsCampaign/2022/firstStepBgMobile.png",
      },
    },
    body: {
      title: constants.Resources.AirPodsCampaign.Step1BodyTitle,
      confirmButton: constants.Resources.AirPodsCampaign.Step1ButtonConfirm,
      cancelButton: constants.Resources.AirPodsCampaign.Step1CancelButton,
      soldOutLabel: constants.Resources.AirPodsCampaign.SoldOutLabel,
      items: [
        {
          id: fsg.id,
          title: fsg.name,
          image: constants.Images.EarpodsCampaign.Campaign.FsgPrize,
          label: isPromo
            ? constants.Resources.AirPodsCampaign.PrizePromoLabel
            : constants.Resources.AirPodsCampaign.PrizeLabel,
          price: isPromo ? fullPrizePrice : fsg.price,
          promoPrice: isPromo ? fsg.price : null,
          color: "red",
          soldOut: !fsg.isActive,
          bottomLabel: constants.Resources.AirPodsCampaign.PrizeBottomLabelFsg,
        },
        {
          id: sk.id,
          title: sk.name,
          image: constants.Images.EarpodsCampaign.Campaign.SkPrize,
          label: isPromo
            ? constants.Resources.AirPodsCampaign.PrizePromoLabel
            : constants.Resources.AirPodsCampaign.PrizeLabel,
          price: isPromo ? fullPrizePrice : sk.price,
          promoPrice: isPromo ? sk.price : null,
          color: "blue",
          soldOut: !sk.isActive,
          bottomLabel: constants.Resources.AirPodsCampaign.PrizeBottomLabelSk,
        },
      ],
    },
  };
};

const formatSecondTab = (details, locations) => {
  var selectedLocation = locations.map((x) => x.id).indexOf(details.address);
  var firstName = "";
  var lastName = "";

  if (details.fullName) {
    var split = details.fullName?.split(" ");
    lastName = split.pop();
    firstName = split.join(" ");
  }

  return {
    title: constants.Resources.AirPodsCampaign.Step2Title,
    description: constants.Resources.AirPodsCampaign.Step2Description,
    inputFirstName: {
      label: constants.Resources.AirPodsCampaign.InputFirstName,
      value: firstName,
      emptyError: constants.Resources.ValidationMessages.Required,
    },
    inputLastName: {
      label: constants.Resources.AirPodsCampaign.InputLastName,
      value: lastName,
      emptyError: constants.Resources.ValidationMessages.Required,
    },
    inputEmail: {
      label: constants.Resources.AirPodsCampaign.InputEmail,
      value: details.email,
      emptyError: constants.Resources.ValidationMessages.Required,
      validationError: constants.Resources.ValidationMessages.EmailFormat,
    },
    inputLocation: {
      label: constants.Resources.AirPodsCampaign.InputLocation,
      emptyError: constants.Resources.ValidationMessages.Required,
      selectEmpty: constants.Resources.AirPodsCampaign.InputLocationPlaceholder,
      value: selectedLocation >= 0 ? `${selectedLocation}` : null,
      locations: locations,
    },
    bottomTextFirst: constants.Resources.AirPodsCampaign.Step2BottomTextFirst,
    consents: details.consent,
    bottomText: constants.Resources.AirPodsCampaign.Step2BottomText,
    button: {
      text: constants.Resources.AirPodsCampaign.Step2Button,
      loader: false,
    },
  };
};

const formatSecondTabConfirm = () => {
  return {
    title: constants.Resources.AirPodsCampaign.Step2ConfirmTitle,
    description: constants.Resources.AirPodsCampaign.Step2ConfirmDescription,
    button: constants.Resources.AirPodsCampaign.Step2ConfirmButton,
  };
};

const formatItemPurchaseSuccess = (isJauti) => {
  return {
    popup: {
      image: constants.Images.Success.Url,
      title: isJauti
        ? constants.Resources.AirPodsCampaign.OrderSuccessPopupJauti
        : constants.Resources.AirPodsCampaign.OrderSuccessPopup,
      iconId: constants.Icons.CloseBold,
      show: true,
    },
    image: constants.Images.EarpodsCampaign.Campaign.PurchaseSuccess,
    title: constants.Resources.AirPodsCampaign.OrderSuccessTitle,
    description: constants.Resources.AirPodsCampaign.OrderSuccessDescription,
    button: constants.Resources.AirPodsCampaign.OrderSuccessButton,
  };
};

const formatItemPurchaseFailure = () => {
  return {
    popup: {
      image: constants.Images.Empty.Url,
      title: constants.Resources.AirPodsCampaign.OrderFailurePopup,
      iconId: constants.Icons.CloseBold,
      show: true,
    },
    image: constants.Images.EarpodsCampaign.Campaign.PurchaseSuccess,
    title: constants.Resources.AirPodsCampaign.OrderFailureTitle,
    description: constants.Resources.AirPodsCampaign.OrderFailureDescription,
    button: constants.Resources.AirPodsCampaign.OrderFailureButton,
  };
};

const formatConsentUpdateData = (consents) => {
  return consents.map((consent) => {
    return {
      consentAgreementStatus: consent.agreed
        ? constants.ConsentStatus.Agree
        : constants.ConsentStatus.Disagree,
      title: consent.title,
      description: consent.description,
      consentType: consent.consentType,
    };
  });
};

const _getCampaignByName = (name) =>
  constants.ActiveCampaigns.find((x) => x.name === name);

export default {
  isCampaignActive,
  isWheelCampaignActive,
  getItemPurchaseCampaignStatus,
  formatCampaignBanner,
  formatInitialPageData,
  formatRefillSuccessPopup,
  formatEmailConfirmationSuccessPopup,
  formatFirstTab,
  formatSecondTab,
  formatSecondTabConfirm,
  formatItemPurchaseSuccess,
  formatItemPurchaseFailure,
  formatConsentUpdateData,
  showCampaign,
};
